/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root';
import { Route as SignupImport } from './routes/signup';
import { Route as PrivateRoutesImport } from './routes/_privateRoutes';
import { Route as ProfileUniqIdImport } from './routes/profile/$uniqId';
import { Route as PrivateRoutesOnboardingImport } from './routes/_privateRoutes/onboarding';
import { Route as PrivateRoutesMainNavBarImport } from './routes/_privateRoutes/_mainNavBar';
import { Route as PrivateRoutesAskYourFriendsIndexImport } from './routes/_privateRoutes/ask-your-friends/index';
import { Route as PrivateRoutesMainNavBarIndexImport } from './routes/_privateRoutes/_mainNavBar/index';
import { Route as PrivateRoutesSettingsEditProfilePictureImport } from './routes/_privateRoutes/settings/editProfilePicture';
import { Route as PrivateRoutesSettingsEditDisplayNameImport } from './routes/_privateRoutes/settings/editDisplayName';
import { Route as PrivateRoutesOnboardingAddProfilePictureImport } from './routes/_privateRoutes/onboarding/addProfilePicture';
import { Route as PrivateRoutesOnboardingAddPhoneNumberImport } from './routes/_privateRoutes/onboarding/addPhoneNumber';
import { Route as PrivateRoutesOnboardingAddDisplayNameImport } from './routes/_privateRoutes/onboarding/addDisplayName';
import { Route as PrivateRoutesOnboardingAddBirthdayImport } from './routes/_privateRoutes/onboarding/addBirthday';
import { Route as PrivateRoutesNotesNewImport } from './routes/_privateRoutes/notes/new';
import { Route as PrivateRoutesNotesNoteIdImport } from './routes/_privateRoutes/notes/$noteId';
import { Route as PrivateRoutesConnectionsSearchImport } from './routes/_privateRoutes/connections/search';
import { Route as PrivateRoutesConnectionsRequestsImport } from './routes/_privateRoutes/connections/requests';
import { Route as PrivateRoutesAskAskIdImport } from './routes/_privateRoutes/ask/$askId';
import { Route as PrivateRoutesAskYourFriendsMultiAskIdImport } from './routes/_privateRoutes/ask-your-friends/$multiAskId';
import { Route as PrivateRoutesMainNavBarSettingsImport } from './routes/_privateRoutes/_mainNavBar/settings';
import { Route as PrivateRoutesMainNavBarSelfImport } from './routes/_privateRoutes/_mainNavBar/self';
import { Route as PrivateRoutesMainNavBarInboxImport } from './routes/_privateRoutes/_mainNavBar/inbox';
import { Route as PrivateRoutesMainNavBarConnectionsImport } from './routes/_privateRoutes/_mainNavBar/connections';

// Create/Update Routes

const SignupRoute = SignupImport.update({
  id: '/signup',
  path: '/signup',
  getParentRoute: () => rootRoute,
} as any);

const PrivateRoutesRoute = PrivateRoutesImport.update({
  id: '/_privateRoutes',
  getParentRoute: () => rootRoute,
} as any);

const ProfileUniqIdRoute = ProfileUniqIdImport.update({
  id: '/profile/$uniqId',
  path: '/profile/$uniqId',
  getParentRoute: () => rootRoute,
} as any);

const PrivateRoutesOnboardingRoute = PrivateRoutesOnboardingImport.update({
  id: '/onboarding',
  path: '/onboarding',
  getParentRoute: () => PrivateRoutesRoute,
} as any);

const PrivateRoutesMainNavBarRoute = PrivateRoutesMainNavBarImport.update({
  id: '/_mainNavBar',
  getParentRoute: () => PrivateRoutesRoute,
} as any);

const PrivateRoutesAskYourFriendsIndexRoute =
  PrivateRoutesAskYourFriendsIndexImport.update({
    id: '/ask-your-friends/',
    path: '/ask-your-friends/',
    getParentRoute: () => PrivateRoutesRoute,
  } as any);

const PrivateRoutesMainNavBarIndexRoute =
  PrivateRoutesMainNavBarIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => PrivateRoutesMainNavBarRoute,
  } as any);

const PrivateRoutesSettingsEditProfilePictureRoute =
  PrivateRoutesSettingsEditProfilePictureImport.update({
    id: '/settings/editProfilePicture',
    path: '/settings/editProfilePicture',
    getParentRoute: () => PrivateRoutesRoute,
  } as any);

const PrivateRoutesSettingsEditDisplayNameRoute =
  PrivateRoutesSettingsEditDisplayNameImport.update({
    id: '/settings/editDisplayName',
    path: '/settings/editDisplayName',
    getParentRoute: () => PrivateRoutesRoute,
  } as any);

const PrivateRoutesOnboardingAddProfilePictureRoute =
  PrivateRoutesOnboardingAddProfilePictureImport.update({
    id: '/addProfilePicture',
    path: '/addProfilePicture',
    getParentRoute: () => PrivateRoutesOnboardingRoute,
  } as any);

const PrivateRoutesOnboardingAddPhoneNumberRoute =
  PrivateRoutesOnboardingAddPhoneNumberImport.update({
    id: '/addPhoneNumber',
    path: '/addPhoneNumber',
    getParentRoute: () => PrivateRoutesOnboardingRoute,
  } as any);

const PrivateRoutesOnboardingAddDisplayNameRoute =
  PrivateRoutesOnboardingAddDisplayNameImport.update({
    id: '/addDisplayName',
    path: '/addDisplayName',
    getParentRoute: () => PrivateRoutesOnboardingRoute,
  } as any);

const PrivateRoutesOnboardingAddBirthdayRoute =
  PrivateRoutesOnboardingAddBirthdayImport.update({
    id: '/addBirthday',
    path: '/addBirthday',
    getParentRoute: () => PrivateRoutesOnboardingRoute,
  } as any);

const PrivateRoutesNotesNewRoute = PrivateRoutesNotesNewImport.update({
  id: '/notes/new',
  path: '/notes/new',
  getParentRoute: () => PrivateRoutesRoute,
} as any);

const PrivateRoutesNotesNoteIdRoute = PrivateRoutesNotesNoteIdImport.update({
  id: '/notes/$noteId',
  path: '/notes/$noteId',
  getParentRoute: () => PrivateRoutesRoute,
} as any);

const PrivateRoutesConnectionsSearchRoute =
  PrivateRoutesConnectionsSearchImport.update({
    id: '/connections/search',
    path: '/connections/search',
    getParentRoute: () => PrivateRoutesRoute,
  } as any);

const PrivateRoutesConnectionsRequestsRoute =
  PrivateRoutesConnectionsRequestsImport.update({
    id: '/connections/requests',
    path: '/connections/requests',
    getParentRoute: () => PrivateRoutesRoute,
  } as any);

const PrivateRoutesAskAskIdRoute = PrivateRoutesAskAskIdImport.update({
  id: '/ask/$askId',
  path: '/ask/$askId',
  getParentRoute: () => PrivateRoutesRoute,
} as any);

const PrivateRoutesAskYourFriendsMultiAskIdRoute =
  PrivateRoutesAskYourFriendsMultiAskIdImport.update({
    id: '/ask-your-friends/$multiAskId',
    path: '/ask-your-friends/$multiAskId',
    getParentRoute: () => PrivateRoutesRoute,
  } as any);

const PrivateRoutesMainNavBarSettingsRoute =
  PrivateRoutesMainNavBarSettingsImport.update({
    id: '/settings',
    path: '/settings',
    getParentRoute: () => PrivateRoutesMainNavBarRoute,
  } as any);

const PrivateRoutesMainNavBarSelfRoute =
  PrivateRoutesMainNavBarSelfImport.update({
    id: '/self',
    path: '/self',
    getParentRoute: () => PrivateRoutesMainNavBarRoute,
  } as any);

const PrivateRoutesMainNavBarInboxRoute =
  PrivateRoutesMainNavBarInboxImport.update({
    id: '/inbox',
    path: '/inbox',
    getParentRoute: () => PrivateRoutesMainNavBarRoute,
  } as any);

const PrivateRoutesMainNavBarConnectionsRoute =
  PrivateRoutesMainNavBarConnectionsImport.update({
    id: '/connections',
    path: '/connections',
    getParentRoute: () => PrivateRoutesMainNavBarRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_privateRoutes': {
      id: '/_privateRoutes';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof PrivateRoutesImport;
      parentRoute: typeof rootRoute;
    };
    '/signup': {
      id: '/signup';
      path: '/signup';
      fullPath: '/signup';
      preLoaderRoute: typeof SignupImport;
      parentRoute: typeof rootRoute;
    };
    '/_privateRoutes/_mainNavBar': {
      id: '/_privateRoutes/_mainNavBar';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof PrivateRoutesMainNavBarImport;
      parentRoute: typeof PrivateRoutesImport;
    };
    '/_privateRoutes/onboarding': {
      id: '/_privateRoutes/onboarding';
      path: '/onboarding';
      fullPath: '/onboarding';
      preLoaderRoute: typeof PrivateRoutesOnboardingImport;
      parentRoute: typeof PrivateRoutesImport;
    };
    '/profile/$uniqId': {
      id: '/profile/$uniqId';
      path: '/profile/$uniqId';
      fullPath: '/profile/$uniqId';
      preLoaderRoute: typeof ProfileUniqIdImport;
      parentRoute: typeof rootRoute;
    };
    '/_privateRoutes/_mainNavBar/connections': {
      id: '/_privateRoutes/_mainNavBar/connections';
      path: '/connections';
      fullPath: '/connections';
      preLoaderRoute: typeof PrivateRoutesMainNavBarConnectionsImport;
      parentRoute: typeof PrivateRoutesMainNavBarImport;
    };
    '/_privateRoutes/_mainNavBar/inbox': {
      id: '/_privateRoutes/_mainNavBar/inbox';
      path: '/inbox';
      fullPath: '/inbox';
      preLoaderRoute: typeof PrivateRoutesMainNavBarInboxImport;
      parentRoute: typeof PrivateRoutesMainNavBarImport;
    };
    '/_privateRoutes/_mainNavBar/self': {
      id: '/_privateRoutes/_mainNavBar/self';
      path: '/self';
      fullPath: '/self';
      preLoaderRoute: typeof PrivateRoutesMainNavBarSelfImport;
      parentRoute: typeof PrivateRoutesMainNavBarImport;
    };
    '/_privateRoutes/_mainNavBar/settings': {
      id: '/_privateRoutes/_mainNavBar/settings';
      path: '/settings';
      fullPath: '/settings';
      preLoaderRoute: typeof PrivateRoutesMainNavBarSettingsImport;
      parentRoute: typeof PrivateRoutesMainNavBarImport;
    };
    '/_privateRoutes/ask-your-friends/$multiAskId': {
      id: '/_privateRoutes/ask-your-friends/$multiAskId';
      path: '/ask-your-friends/$multiAskId';
      fullPath: '/ask-your-friends/$multiAskId';
      preLoaderRoute: typeof PrivateRoutesAskYourFriendsMultiAskIdImport;
      parentRoute: typeof PrivateRoutesImport;
    };
    '/_privateRoutes/ask/$askId': {
      id: '/_privateRoutes/ask/$askId';
      path: '/ask/$askId';
      fullPath: '/ask/$askId';
      preLoaderRoute: typeof PrivateRoutesAskAskIdImport;
      parentRoute: typeof PrivateRoutesImport;
    };
    '/_privateRoutes/connections/requests': {
      id: '/_privateRoutes/connections/requests';
      path: '/connections/requests';
      fullPath: '/connections/requests';
      preLoaderRoute: typeof PrivateRoutesConnectionsRequestsImport;
      parentRoute: typeof PrivateRoutesImport;
    };
    '/_privateRoutes/connections/search': {
      id: '/_privateRoutes/connections/search';
      path: '/connections/search';
      fullPath: '/connections/search';
      preLoaderRoute: typeof PrivateRoutesConnectionsSearchImport;
      parentRoute: typeof PrivateRoutesImport;
    };
    '/_privateRoutes/notes/$noteId': {
      id: '/_privateRoutes/notes/$noteId';
      path: '/notes/$noteId';
      fullPath: '/notes/$noteId';
      preLoaderRoute: typeof PrivateRoutesNotesNoteIdImport;
      parentRoute: typeof PrivateRoutesImport;
    };
    '/_privateRoutes/notes/new': {
      id: '/_privateRoutes/notes/new';
      path: '/notes/new';
      fullPath: '/notes/new';
      preLoaderRoute: typeof PrivateRoutesNotesNewImport;
      parentRoute: typeof PrivateRoutesImport;
    };
    '/_privateRoutes/onboarding/addBirthday': {
      id: '/_privateRoutes/onboarding/addBirthday';
      path: '/addBirthday';
      fullPath: '/onboarding/addBirthday';
      preLoaderRoute: typeof PrivateRoutesOnboardingAddBirthdayImport;
      parentRoute: typeof PrivateRoutesOnboardingImport;
    };
    '/_privateRoutes/onboarding/addDisplayName': {
      id: '/_privateRoutes/onboarding/addDisplayName';
      path: '/addDisplayName';
      fullPath: '/onboarding/addDisplayName';
      preLoaderRoute: typeof PrivateRoutesOnboardingAddDisplayNameImport;
      parentRoute: typeof PrivateRoutesOnboardingImport;
    };
    '/_privateRoutes/onboarding/addPhoneNumber': {
      id: '/_privateRoutes/onboarding/addPhoneNumber';
      path: '/addPhoneNumber';
      fullPath: '/onboarding/addPhoneNumber';
      preLoaderRoute: typeof PrivateRoutesOnboardingAddPhoneNumberImport;
      parentRoute: typeof PrivateRoutesOnboardingImport;
    };
    '/_privateRoutes/onboarding/addProfilePicture': {
      id: '/_privateRoutes/onboarding/addProfilePicture';
      path: '/addProfilePicture';
      fullPath: '/onboarding/addProfilePicture';
      preLoaderRoute: typeof PrivateRoutesOnboardingAddProfilePictureImport;
      parentRoute: typeof PrivateRoutesOnboardingImport;
    };
    '/_privateRoutes/settings/editDisplayName': {
      id: '/_privateRoutes/settings/editDisplayName';
      path: '/settings/editDisplayName';
      fullPath: '/settings/editDisplayName';
      preLoaderRoute: typeof PrivateRoutesSettingsEditDisplayNameImport;
      parentRoute: typeof PrivateRoutesImport;
    };
    '/_privateRoutes/settings/editProfilePicture': {
      id: '/_privateRoutes/settings/editProfilePicture';
      path: '/settings/editProfilePicture';
      fullPath: '/settings/editProfilePicture';
      preLoaderRoute: typeof PrivateRoutesSettingsEditProfilePictureImport;
      parentRoute: typeof PrivateRoutesImport;
    };
    '/_privateRoutes/_mainNavBar/': {
      id: '/_privateRoutes/_mainNavBar/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof PrivateRoutesMainNavBarIndexImport;
      parentRoute: typeof PrivateRoutesMainNavBarImport;
    };
    '/_privateRoutes/ask-your-friends/': {
      id: '/_privateRoutes/ask-your-friends/';
      path: '/ask-your-friends';
      fullPath: '/ask-your-friends';
      preLoaderRoute: typeof PrivateRoutesAskYourFriendsIndexImport;
      parentRoute: typeof PrivateRoutesImport;
    };
  }
}

// Create and export the route tree

interface PrivateRoutesMainNavBarRouteChildren {
  PrivateRoutesMainNavBarConnectionsRoute: typeof PrivateRoutesMainNavBarConnectionsRoute;
  PrivateRoutesMainNavBarInboxRoute: typeof PrivateRoutesMainNavBarInboxRoute;
  PrivateRoutesMainNavBarSelfRoute: typeof PrivateRoutesMainNavBarSelfRoute;
  PrivateRoutesMainNavBarSettingsRoute: typeof PrivateRoutesMainNavBarSettingsRoute;
  PrivateRoutesMainNavBarIndexRoute: typeof PrivateRoutesMainNavBarIndexRoute;
}

const PrivateRoutesMainNavBarRouteChildren: PrivateRoutesMainNavBarRouteChildren =
  {
    PrivateRoutesMainNavBarConnectionsRoute:
      PrivateRoutesMainNavBarConnectionsRoute,
    PrivateRoutesMainNavBarInboxRoute: PrivateRoutesMainNavBarInboxRoute,
    PrivateRoutesMainNavBarSelfRoute: PrivateRoutesMainNavBarSelfRoute,
    PrivateRoutesMainNavBarSettingsRoute: PrivateRoutesMainNavBarSettingsRoute,
    PrivateRoutesMainNavBarIndexRoute: PrivateRoutesMainNavBarIndexRoute,
  };

const PrivateRoutesMainNavBarRouteWithChildren =
  PrivateRoutesMainNavBarRoute._addFileChildren(
    PrivateRoutesMainNavBarRouteChildren,
  );

interface PrivateRoutesOnboardingRouteChildren {
  PrivateRoutesOnboardingAddBirthdayRoute: typeof PrivateRoutesOnboardingAddBirthdayRoute;
  PrivateRoutesOnboardingAddDisplayNameRoute: typeof PrivateRoutesOnboardingAddDisplayNameRoute;
  PrivateRoutesOnboardingAddPhoneNumberRoute: typeof PrivateRoutesOnboardingAddPhoneNumberRoute;
  PrivateRoutesOnboardingAddProfilePictureRoute: typeof PrivateRoutesOnboardingAddProfilePictureRoute;
}

const PrivateRoutesOnboardingRouteChildren: PrivateRoutesOnboardingRouteChildren =
  {
    PrivateRoutesOnboardingAddBirthdayRoute:
      PrivateRoutesOnboardingAddBirthdayRoute,
    PrivateRoutesOnboardingAddDisplayNameRoute:
      PrivateRoutesOnboardingAddDisplayNameRoute,
    PrivateRoutesOnboardingAddPhoneNumberRoute:
      PrivateRoutesOnboardingAddPhoneNumberRoute,
    PrivateRoutesOnboardingAddProfilePictureRoute:
      PrivateRoutesOnboardingAddProfilePictureRoute,
  };

const PrivateRoutesOnboardingRouteWithChildren =
  PrivateRoutesOnboardingRoute._addFileChildren(
    PrivateRoutesOnboardingRouteChildren,
  );

interface PrivateRoutesRouteChildren {
  PrivateRoutesMainNavBarRoute: typeof PrivateRoutesMainNavBarRouteWithChildren;
  PrivateRoutesOnboardingRoute: typeof PrivateRoutesOnboardingRouteWithChildren;
  PrivateRoutesAskYourFriendsMultiAskIdRoute: typeof PrivateRoutesAskYourFriendsMultiAskIdRoute;
  PrivateRoutesAskAskIdRoute: typeof PrivateRoutesAskAskIdRoute;
  PrivateRoutesConnectionsRequestsRoute: typeof PrivateRoutesConnectionsRequestsRoute;
  PrivateRoutesConnectionsSearchRoute: typeof PrivateRoutesConnectionsSearchRoute;
  PrivateRoutesNotesNoteIdRoute: typeof PrivateRoutesNotesNoteIdRoute;
  PrivateRoutesNotesNewRoute: typeof PrivateRoutesNotesNewRoute;
  PrivateRoutesSettingsEditDisplayNameRoute: typeof PrivateRoutesSettingsEditDisplayNameRoute;
  PrivateRoutesSettingsEditProfilePictureRoute: typeof PrivateRoutesSettingsEditProfilePictureRoute;
  PrivateRoutesAskYourFriendsIndexRoute: typeof PrivateRoutesAskYourFriendsIndexRoute;
}

const PrivateRoutesRouteChildren: PrivateRoutesRouteChildren = {
  PrivateRoutesMainNavBarRoute: PrivateRoutesMainNavBarRouteWithChildren,
  PrivateRoutesOnboardingRoute: PrivateRoutesOnboardingRouteWithChildren,
  PrivateRoutesAskYourFriendsMultiAskIdRoute:
    PrivateRoutesAskYourFriendsMultiAskIdRoute,
  PrivateRoutesAskAskIdRoute: PrivateRoutesAskAskIdRoute,
  PrivateRoutesConnectionsRequestsRoute: PrivateRoutesConnectionsRequestsRoute,
  PrivateRoutesConnectionsSearchRoute: PrivateRoutesConnectionsSearchRoute,
  PrivateRoutesNotesNoteIdRoute: PrivateRoutesNotesNoteIdRoute,
  PrivateRoutesNotesNewRoute: PrivateRoutesNotesNewRoute,
  PrivateRoutesSettingsEditDisplayNameRoute:
    PrivateRoutesSettingsEditDisplayNameRoute,
  PrivateRoutesSettingsEditProfilePictureRoute:
    PrivateRoutesSettingsEditProfilePictureRoute,
  PrivateRoutesAskYourFriendsIndexRoute: PrivateRoutesAskYourFriendsIndexRoute,
};

const PrivateRoutesRouteWithChildren = PrivateRoutesRoute._addFileChildren(
  PrivateRoutesRouteChildren,
);

export interface FileRoutesByFullPath {
  '': typeof PrivateRoutesMainNavBarRouteWithChildren;
  '/signup': typeof SignupRoute;
  '/onboarding': typeof PrivateRoutesOnboardingRouteWithChildren;
  '/profile/$uniqId': typeof ProfileUniqIdRoute;
  '/connections': typeof PrivateRoutesMainNavBarConnectionsRoute;
  '/inbox': typeof PrivateRoutesMainNavBarInboxRoute;
  '/self': typeof PrivateRoutesMainNavBarSelfRoute;
  '/settings': typeof PrivateRoutesMainNavBarSettingsRoute;
  '/ask-your-friends/$multiAskId': typeof PrivateRoutesAskYourFriendsMultiAskIdRoute;
  '/ask/$askId': typeof PrivateRoutesAskAskIdRoute;
  '/connections/requests': typeof PrivateRoutesConnectionsRequestsRoute;
  '/connections/search': typeof PrivateRoutesConnectionsSearchRoute;
  '/notes/$noteId': typeof PrivateRoutesNotesNoteIdRoute;
  '/notes/new': typeof PrivateRoutesNotesNewRoute;
  '/onboarding/addBirthday': typeof PrivateRoutesOnboardingAddBirthdayRoute;
  '/onboarding/addDisplayName': typeof PrivateRoutesOnboardingAddDisplayNameRoute;
  '/onboarding/addPhoneNumber': typeof PrivateRoutesOnboardingAddPhoneNumberRoute;
  '/onboarding/addProfilePicture': typeof PrivateRoutesOnboardingAddProfilePictureRoute;
  '/settings/editDisplayName': typeof PrivateRoutesSettingsEditDisplayNameRoute;
  '/settings/editProfilePicture': typeof PrivateRoutesSettingsEditProfilePictureRoute;
  '/': typeof PrivateRoutesMainNavBarIndexRoute;
  '/ask-your-friends': typeof PrivateRoutesAskYourFriendsIndexRoute;
}

export interface FileRoutesByTo {
  '': typeof PrivateRoutesRouteWithChildren;
  '/signup': typeof SignupRoute;
  '/onboarding': typeof PrivateRoutesOnboardingRouteWithChildren;
  '/profile/$uniqId': typeof ProfileUniqIdRoute;
  '/connections': typeof PrivateRoutesMainNavBarConnectionsRoute;
  '/inbox': typeof PrivateRoutesMainNavBarInboxRoute;
  '/self': typeof PrivateRoutesMainNavBarSelfRoute;
  '/settings': typeof PrivateRoutesMainNavBarSettingsRoute;
  '/ask-your-friends/$multiAskId': typeof PrivateRoutesAskYourFriendsMultiAskIdRoute;
  '/ask/$askId': typeof PrivateRoutesAskAskIdRoute;
  '/connections/requests': typeof PrivateRoutesConnectionsRequestsRoute;
  '/connections/search': typeof PrivateRoutesConnectionsSearchRoute;
  '/notes/$noteId': typeof PrivateRoutesNotesNoteIdRoute;
  '/notes/new': typeof PrivateRoutesNotesNewRoute;
  '/onboarding/addBirthday': typeof PrivateRoutesOnboardingAddBirthdayRoute;
  '/onboarding/addDisplayName': typeof PrivateRoutesOnboardingAddDisplayNameRoute;
  '/onboarding/addPhoneNumber': typeof PrivateRoutesOnboardingAddPhoneNumberRoute;
  '/onboarding/addProfilePicture': typeof PrivateRoutesOnboardingAddProfilePictureRoute;
  '/settings/editDisplayName': typeof PrivateRoutesSettingsEditDisplayNameRoute;
  '/settings/editProfilePicture': typeof PrivateRoutesSettingsEditProfilePictureRoute;
  '/': typeof PrivateRoutesMainNavBarIndexRoute;
  '/ask-your-friends': typeof PrivateRoutesAskYourFriendsIndexRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/_privateRoutes': typeof PrivateRoutesRouteWithChildren;
  '/signup': typeof SignupRoute;
  '/_privateRoutes/_mainNavBar': typeof PrivateRoutesMainNavBarRouteWithChildren;
  '/_privateRoutes/onboarding': typeof PrivateRoutesOnboardingRouteWithChildren;
  '/profile/$uniqId': typeof ProfileUniqIdRoute;
  '/_privateRoutes/_mainNavBar/connections': typeof PrivateRoutesMainNavBarConnectionsRoute;
  '/_privateRoutes/_mainNavBar/inbox': typeof PrivateRoutesMainNavBarInboxRoute;
  '/_privateRoutes/_mainNavBar/self': typeof PrivateRoutesMainNavBarSelfRoute;
  '/_privateRoutes/_mainNavBar/settings': typeof PrivateRoutesMainNavBarSettingsRoute;
  '/_privateRoutes/ask-your-friends/$multiAskId': typeof PrivateRoutesAskYourFriendsMultiAskIdRoute;
  '/_privateRoutes/ask/$askId': typeof PrivateRoutesAskAskIdRoute;
  '/_privateRoutes/connections/requests': typeof PrivateRoutesConnectionsRequestsRoute;
  '/_privateRoutes/connections/search': typeof PrivateRoutesConnectionsSearchRoute;
  '/_privateRoutes/notes/$noteId': typeof PrivateRoutesNotesNoteIdRoute;
  '/_privateRoutes/notes/new': typeof PrivateRoutesNotesNewRoute;
  '/_privateRoutes/onboarding/addBirthday': typeof PrivateRoutesOnboardingAddBirthdayRoute;
  '/_privateRoutes/onboarding/addDisplayName': typeof PrivateRoutesOnboardingAddDisplayNameRoute;
  '/_privateRoutes/onboarding/addPhoneNumber': typeof PrivateRoutesOnboardingAddPhoneNumberRoute;
  '/_privateRoutes/onboarding/addProfilePicture': typeof PrivateRoutesOnboardingAddProfilePictureRoute;
  '/_privateRoutes/settings/editDisplayName': typeof PrivateRoutesSettingsEditDisplayNameRoute;
  '/_privateRoutes/settings/editProfilePicture': typeof PrivateRoutesSettingsEditProfilePictureRoute;
  '/_privateRoutes/_mainNavBar/': typeof PrivateRoutesMainNavBarIndexRoute;
  '/_privateRoutes/ask-your-friends/': typeof PrivateRoutesAskYourFriendsIndexRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | ''
    | '/signup'
    | '/onboarding'
    | '/profile/$uniqId'
    | '/connections'
    | '/inbox'
    | '/self'
    | '/settings'
    | '/ask-your-friends/$multiAskId'
    | '/ask/$askId'
    | '/connections/requests'
    | '/connections/search'
    | '/notes/$noteId'
    | '/notes/new'
    | '/onboarding/addBirthday'
    | '/onboarding/addDisplayName'
    | '/onboarding/addPhoneNumber'
    | '/onboarding/addProfilePicture'
    | '/settings/editDisplayName'
    | '/settings/editProfilePicture'
    | '/'
    | '/ask-your-friends';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | ''
    | '/signup'
    | '/onboarding'
    | '/profile/$uniqId'
    | '/connections'
    | '/inbox'
    | '/self'
    | '/settings'
    | '/ask-your-friends/$multiAskId'
    | '/ask/$askId'
    | '/connections/requests'
    | '/connections/search'
    | '/notes/$noteId'
    | '/notes/new'
    | '/onboarding/addBirthday'
    | '/onboarding/addDisplayName'
    | '/onboarding/addPhoneNumber'
    | '/onboarding/addProfilePicture'
    | '/settings/editDisplayName'
    | '/settings/editProfilePicture'
    | '/'
    | '/ask-your-friends';
  id:
    | '__root__'
    | '/_privateRoutes'
    | '/signup'
    | '/_privateRoutes/_mainNavBar'
    | '/_privateRoutes/onboarding'
    | '/profile/$uniqId'
    | '/_privateRoutes/_mainNavBar/connections'
    | '/_privateRoutes/_mainNavBar/inbox'
    | '/_privateRoutes/_mainNavBar/self'
    | '/_privateRoutes/_mainNavBar/settings'
    | '/_privateRoutes/ask-your-friends/$multiAskId'
    | '/_privateRoutes/ask/$askId'
    | '/_privateRoutes/connections/requests'
    | '/_privateRoutes/connections/search'
    | '/_privateRoutes/notes/$noteId'
    | '/_privateRoutes/notes/new'
    | '/_privateRoutes/onboarding/addBirthday'
    | '/_privateRoutes/onboarding/addDisplayName'
    | '/_privateRoutes/onboarding/addPhoneNumber'
    | '/_privateRoutes/onboarding/addProfilePicture'
    | '/_privateRoutes/settings/editDisplayName'
    | '/_privateRoutes/settings/editProfilePicture'
    | '/_privateRoutes/_mainNavBar/'
    | '/_privateRoutes/ask-your-friends/';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  PrivateRoutesRoute: typeof PrivateRoutesRouteWithChildren;
  SignupRoute: typeof SignupRoute;
  ProfileUniqIdRoute: typeof ProfileUniqIdRoute;
}

const rootRouteChildren: RootRouteChildren = {
  PrivateRoutesRoute: PrivateRoutesRouteWithChildren,
  SignupRoute: SignupRoute,
  ProfileUniqIdRoute: ProfileUniqIdRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_privateRoutes",
        "/signup",
        "/profile/$uniqId"
      ]
    },
    "/_privateRoutes": {
      "filePath": "_privateRoutes.tsx",
      "children": [
        "/_privateRoutes/_mainNavBar",
        "/_privateRoutes/onboarding",
        "/_privateRoutes/ask-your-friends/$multiAskId",
        "/_privateRoutes/ask/$askId",
        "/_privateRoutes/connections/requests",
        "/_privateRoutes/connections/search",
        "/_privateRoutes/notes/$noteId",
        "/_privateRoutes/notes/new",
        "/_privateRoutes/settings/editDisplayName",
        "/_privateRoutes/settings/editProfilePicture",
        "/_privateRoutes/ask-your-friends/"
      ]
    },
    "/signup": {
      "filePath": "signup.tsx"
    },
    "/_privateRoutes/_mainNavBar": {
      "filePath": "_privateRoutes/_mainNavBar.tsx",
      "parent": "/_privateRoutes",
      "children": [
        "/_privateRoutes/_mainNavBar/connections",
        "/_privateRoutes/_mainNavBar/inbox",
        "/_privateRoutes/_mainNavBar/self",
        "/_privateRoutes/_mainNavBar/settings",
        "/_privateRoutes/_mainNavBar/"
      ]
    },
    "/_privateRoutes/onboarding": {
      "filePath": "_privateRoutes/onboarding.tsx",
      "parent": "/_privateRoutes",
      "children": [
        "/_privateRoutes/onboarding/addBirthday",
        "/_privateRoutes/onboarding/addDisplayName",
        "/_privateRoutes/onboarding/addPhoneNumber",
        "/_privateRoutes/onboarding/addProfilePicture"
      ]
    },
    "/profile/$uniqId": {
      "filePath": "profile/$uniqId.tsx"
    },
    "/_privateRoutes/_mainNavBar/connections": {
      "filePath": "_privateRoutes/_mainNavBar/connections.tsx",
      "parent": "/_privateRoutes/_mainNavBar"
    },
    "/_privateRoutes/_mainNavBar/inbox": {
      "filePath": "_privateRoutes/_mainNavBar/inbox.tsx",
      "parent": "/_privateRoutes/_mainNavBar"
    },
    "/_privateRoutes/_mainNavBar/self": {
      "filePath": "_privateRoutes/_mainNavBar/self.tsx",
      "parent": "/_privateRoutes/_mainNavBar"
    },
    "/_privateRoutes/_mainNavBar/settings": {
      "filePath": "_privateRoutes/_mainNavBar/settings.tsx",
      "parent": "/_privateRoutes/_mainNavBar"
    },
    "/_privateRoutes/ask-your-friends/$multiAskId": {
      "filePath": "_privateRoutes/ask-your-friends/$multiAskId.tsx",
      "parent": "/_privateRoutes"
    },
    "/_privateRoutes/ask/$askId": {
      "filePath": "_privateRoutes/ask/$askId.tsx",
      "parent": "/_privateRoutes"
    },
    "/_privateRoutes/connections/requests": {
      "filePath": "_privateRoutes/connections/requests.tsx",
      "parent": "/_privateRoutes"
    },
    "/_privateRoutes/connections/search": {
      "filePath": "_privateRoutes/connections/search.tsx",
      "parent": "/_privateRoutes"
    },
    "/_privateRoutes/notes/$noteId": {
      "filePath": "_privateRoutes/notes/$noteId.tsx",
      "parent": "/_privateRoutes"
    },
    "/_privateRoutes/notes/new": {
      "filePath": "_privateRoutes/notes/new.tsx",
      "parent": "/_privateRoutes"
    },
    "/_privateRoutes/onboarding/addBirthday": {
      "filePath": "_privateRoutes/onboarding/addBirthday.tsx",
      "parent": "/_privateRoutes/onboarding"
    },
    "/_privateRoutes/onboarding/addDisplayName": {
      "filePath": "_privateRoutes/onboarding/addDisplayName.tsx",
      "parent": "/_privateRoutes/onboarding"
    },
    "/_privateRoutes/onboarding/addPhoneNumber": {
      "filePath": "_privateRoutes/onboarding/addPhoneNumber.tsx",
      "parent": "/_privateRoutes/onboarding"
    },
    "/_privateRoutes/onboarding/addProfilePicture": {
      "filePath": "_privateRoutes/onboarding/addProfilePicture.tsx",
      "parent": "/_privateRoutes/onboarding"
    },
    "/_privateRoutes/settings/editDisplayName": {
      "filePath": "_privateRoutes/settings/editDisplayName.tsx",
      "parent": "/_privateRoutes"
    },
    "/_privateRoutes/settings/editProfilePicture": {
      "filePath": "_privateRoutes/settings/editProfilePicture.tsx",
      "parent": "/_privateRoutes"
    },
    "/_privateRoutes/_mainNavBar/": {
      "filePath": "_privateRoutes/_mainNavBar/index.tsx",
      "parent": "/_privateRoutes/_mainNavBar"
    },
    "/_privateRoutes/ask-your-friends/": {
      "filePath": "_privateRoutes/ask-your-friends/index.tsx",
      "parent": "/_privateRoutes"
    }
  }
}
ROUTE_MANIFEST_END */
