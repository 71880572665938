import { useEffect } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { Action, Page } from '@/../../server/types/loggingTypes';
import PhoneVerification, { PhoneVerificationContext } from '@/components/PhoneVerification';
import { getStatsigClient } from '@/utils/statsig';

export const Route = createFileRoute('/_privateRoutes/onboarding/addPhoneNumber')({
  component: AddPhoneNumber,
});

export default function AddPhoneNumber() {
  useEffect(() => {
    getStatsigClient().logEvent(Page.PHONE_NUMBER_PAGE, Action.VIEW);
  }, []);

  return (
    <Box bg="black" minH="100vh" px={4} position="relative">
      <Flex direction="column" maxW="container.sm" mx="auto" pt={12} minH="100vh">
        <Text fontSize="4xl" fontWeight="bold" color="white" mb={2}>
          Add phone number
        </Text>
        <Text fontSize="lg" color="gray.300" mb={8}>
          Add your phone number to secure your account with two-factor authentication.
        </Text>
        <VStack align="center" spacing={4} width="100%">
          <PhoneVerification context={PhoneVerificationContext.Onboarding} />
        </VStack>
      </Flex>
    </Box>
  );
}
