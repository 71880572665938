import { useEffect } from 'react';
import { createFileRoute, useParams } from '@tanstack/react-router';
import AddOrUpdateNote from '@/components/AddOrUpdateNote';
import { getStatsigClient } from '@/utils/statsig';
import { Action } from '../../../../../server/types/loggingTypes';
import { Page } from '../../../../../server/types/loggingTypes';

export const Route = createFileRoute('/_privateRoutes/notes/$noteId')({
  component: Note,
});

export default function Note() {
  const { noteId } = useParams({ from: '/_privateRoutes/notes/$noteId' });
  useEffect(() => {
    getStatsigClient().logEvent(Page.NOTES_PAGE, Action.VIEW);
  }, []);
  return <AddOrUpdateNote noteId={noteId} />;
}
