import { Outlet, createRootRouteWithContext } from '@tanstack/react-router';
import { Box, ChakraProvider } from '@chakra-ui/react';
import { StatsigProvider } from '@statsig/react-bindings';
import { TanStackRouterDevtools } from '@tanstack/react-router-devtools';
import { DevTools } from 'jotai-devtools';
import { AuthState } from '@/store/slices/authSlice';
import { dotsTheme } from '../styles/theme';
import { getStatsigClient, statsigSDKKey } from '../utils/statsig';
import '../App.css';

const client = getStatsigClient();

interface RouterContext {
  auth: AuthState;
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => (
    <>
      <StatsigProvider client={client} sdkKey={statsigSDKKey}>
        <ChakraProvider theme={dotsTheme}>
          <Box className="App__Container" minHeight="100vh" backgroundColor="#1C1C1C">
            <Outlet />
          </Box>
        </ChakraProvider>
      </StatsigProvider>
      <TanStackRouterDevtools />
      <DevTools />
    </>
  ),
});
