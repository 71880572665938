import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, createFileRoute, useNavigate, useParams } from '@tanstack/react-router';
import {
  Box,
  Button,
  Circle,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { User } from '@/types/user';
import { Action, Page } from '@/../../server/types/loggingTypes';
import logo from '@/assets/logo.png';
import more from '@/assets/more.svg';
import Ask from '@/components/Ask';
import OpenAppButton from '@/components/OpenAppButton';
import BottomSheet from '@/components/lib/BottomSheet';
import { useConnectionMutations } from '@/hooks/useConnectionMutations';
import { useAppSelector } from '@/store/hooks';
import { sizing } from '@/styles/sizing';
import { getStatsigClient } from '@/utils/statsig';
import { UserRelationship } from '../../../../shared/types/connections';

export const Route = createFileRoute('/profile/$uniqId')({
  component: UserProfile,
});

export default function UserProfile() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const authState = useAppSelector((state) => state.auth);
  const navigate = useNavigate({ from: '/profile/$uniqId' });
  const onQuestion = () => {
    onOpen();
  };

  const { uniqId } = useParams({ from: '/profile/$uniqId' });
  console.log(`askeeUniqueId: ${uniqId}`);
  const isVisitingOwnProfile = useMemo(
    () => authState.isAuthenticated && authState.uniqId === uniqId,
    [authState.isAuthenticated, authState.uniqId, uniqId],
  );

  const { data: questionReceiver, error } = useQuery<User, AxiosError>({
    queryKey: ['getAskeeUser', uniqId],
    queryFn: async ({ queryKey: [, uniqId] }) => {
      getStatsigClient().logEvent('start_fetching_asklink_data');
      const userRes = await axios.post('/api/getAskeeUser', { uniqId });
      getStatsigClient().logEvent('end_fetching_asklink_data');
      return userRes.data.user;
    },
    retry: (failureCount, error) => {
      if (error.response?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    enabled: !isVisitingOwnProfile,
  });

  const [isMoreSheetOpen, setIsMoreSheetOpen] = useState(false);
  const [isDisconnectConfirmSheetOpen, setIsDisconnectConfirmSheetOpen] = useState(false);
  const { disconnect, isLoading } = useConnectionMutations();

  useEffect(() => {
    if (error) {
      console.error('Error:', error);
      if (error.response?.status === 404) {
        if (authState.isAuthenticated) {
          navigate({ to: '/' });
        } else {
          navigate({ to: '/signup' });
        }
        return;
      }
      navigate({ to: '/' });
    }
  }, [error, navigate, authState.isAuthenticated]);

  useEffect(() => {
    if (isVisitingOwnProfile) {
      console.log(
        `should redirect to profile page because authState.uniqId: ${authState.uniqId} and askeeUniqueId: ${uniqId}`,
      );
      navigate({ to: '/' });
    }
  }, [uniqId, isVisitingOwnProfile]);

  useEffect(() => {
    getStatsigClient().logEvent(Page.EXTERNAL_PROFILE_PAGE, Action.VIEW);
  }, []);

  const handleClickCreateYourAccount = useCallback(() => {
    navigate({
      to: '/signup',
      search: (prev) => ({ ...prev, onboardingRewardUniqueId: uniqId }),
    });
  }, [navigate, uniqId]);

  const queryClient = useQueryClient();

  return (
    <Flex direction="column" width="100%" p={`${sizing.horizontalGuttersPx}px`} alignItems="center">
      <Flex align="center" justify="flex-end" width="100%">
        <OpenAppButton position="relative" deeplinkPath={`/profile/${uniqId}`} />
      </Flex>
      <Flex
        width="100%"
        maxW={`${sizing.maxAppWidthPx}px`}
        position="relative"
        justify="space-between"
        align="center"
        mt={{ base: '16px', md: '32px', lg: '32px' }}
        mb="32px"
      >
        <Box as={Link} to="/" target="_self" rel="noopener noreferrer" display="inline-block">
          <Box
            as="img"
            src={logo}
            alt="dots logo"
            height={{ base: '24px', md: '32px', lg: '32px' }}
            width="auto"
            cursor="pointer"
            className="transition-opacity hover:opacity-80"
          />
        </Box>
        {questionReceiver?.relationship === UserRelationship.CONNECTED && (
          <div className="cursor-pointer" onClick={() => setIsMoreSheetOpen(true)}>
            <img src={more} />
          </div>
        )}
      </Flex>
      <Box maxW={`${sizing.maxAppWidthPx}px`} width="100%">
        <Ask questionReceiver={questionReceiver} isInternal={false} onQuestion={onQuestion} />
      </Box>
      <BottomSheet isOpen={isMoreSheetOpen} onClose={() => setIsMoreSheetOpen(false)}>
        <div>
          <div className="flex flex-col items-center text-center">
            <div
              className="text-red-400 font-medium py-4 w-full text-center"
              onClick={() => {
                setIsMoreSheetOpen(false);
                setTimeout(() => {
                  setIsDisconnectConfirmSheetOpen(true);
                }, 100);
              }}
            >
              Disconnect
            </div>
            <div
              className="font-medium py-4 w-full text-center"
              // Chakra UI resets the border-top to 0px, so we need to add it back
              // Tailwind won't work here because of it
              style={{ borderTop: '1px solid oklch(0.928 0.006 264.531)' }}
              onClick={() => setIsMoreSheetOpen(false)}
            >
              Cancel
            </div>
          </div>
        </div>
      </BottomSheet>
      <BottomSheet isOpen={isDisconnectConfirmSheetOpen} onClose={() => setIsDisconnectConfirmSheetOpen(false)}>
        <div className="flex flex-col items-center text-center">
          <div className="text-lg mt-2 p-5">Do you want to disconnect with {questionReceiver?.displayName}?</div>
          <div
            className="text-red-400 font-medium py-4 w-full text-center"
            // Chakra UI resets the border-top to 0px, so we need to add it back
            // Tailwind won't work here because of it
            style={{ borderTop: '1px solid oklch(0.928 0.006 264.531)' }}
            onClick={() =>
              disconnect(questionReceiver?.userId ?? '', {
                onSuccess: () => {
                  queryClient.setQueryData(['getAskeeUser', uniqId], (oldData: User) => ({
                    ...oldData,
                    relationship: UserRelationship.DISCONNECTED,
                  }));
                  setIsDisconnectConfirmSheetOpen(false);
                },
              })
            }
          >
            {isLoading.disconnect ? <Spinner /> : 'Confirm'}
          </div>
          <div
            className="font-medium py-4 w-full text-center"
            // Chakra UI resets the border-top to 0px, so we need to add it back
            // Tailwind won't work here because of it
            style={{ borderTop: '1px solid oklch(0.928 0.006 264.531)' }}
            onClick={() => setIsDisconnectConfirmSheetOpen(false)}
          >
            Cancel
          </div>
        </div>
      </BottomSheet>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay backdropFilter="blur(5px)" />
        <ModalContent bg="#1C1C1C" color="white" borderRadius="30px">
          <ModalHeader textAlign="center" color="white" fontSize="2xl">
            Create a Dots Profile to Ask <br></br>
            <Text as="span" color="#FF0050">
              {questionReceiver?.displayName}
            </Text>{' '}
            Questions!
            <Text as="h2" color="white" fontSize="sm" mt={4} fontWeight="normal">
              Dots is a private network that allows you to connect with anyone & see who they really are
            </Text>
          </ModalHeader>
          <ModalBody>
            <VStack align="start" spacing={4} ml="20px">
              <HStack>
                <Circle size="20px" bg="white" color="#1C1C1C">
                  <Text fontSize="sm" fontWeight="bold">
                    1
                  </Text>
                </Circle>
                <Text fontSize="sm" color="white">
                  Ask unlimited questions - for free
                </Text>
              </HStack>
              <HStack>
                <Circle size="20px" bg="white" color="#1C1C1C">
                  <Text fontSize="sm" fontWeight="bold">
                    2
                  </Text>
                </Circle>
                <Text fontSize="sm" color="white">
                  Get instant responses
                </Text>
              </HStack>
              <HStack>
                <Circle size="20px" bg="white" color="#1C1C1C">
                  <Text fontSize="sm" fontWeight="bold">
                    3
                  </Text>
                </Circle>
                <Text fontSize="sm" color="white">
                  Get your own profile - show the real you
                </Text>
              </HStack>
            </VStack>
          </ModalBody>
          <ModalFooter display="flex" flexDirection="column" justifyContent="center">
            <Button
              colorScheme="red"
              backgroundColor="#FF0050"
              _hover={{ bg: '#FF3371' }}
              borderRadius="30"
              size="lg"
              onClick={handleClickCreateYourAccount}
            >
              Create Your Account
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
