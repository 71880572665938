import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createFileRoute } from '@tanstack/react-router';
import { Box, Button, Flex, Input, Link, Text, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { Action, ItemType, Page } from '@/../../server/types/loggingTypes';
import { setAuthInfo } from '@/store/slices/authSlice';
import { getStatsigClient } from '@/utils/statsig';

export const Route = createFileRoute('/_privateRoutes/onboarding/addDisplayName')({
  component: AddDisplayName,
});

export default function AddDisplayName() {
  useEffect(() => {
    getStatsigClient().logEvent(Page.DISPLAY_NAME_PAGE, Action.VIEW);
  }, []);

  const [displayName, setDisplayName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();
  const maxDisplayNameLength = 20;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!displayName) {
      getStatsigClient().logEvent(Page.DISPLAY_NAME_PAGE, Action.TYPING, {
        item_name: 'display_name',
      });
    }
    const newName = e.target.value;
    if (newName.length <= maxDisplayNameLength) {
      setDisplayName(newName);
    }
  };

  const handleSubmit = async () => {
    getStatsigClient().logEvent(Page.DISPLAY_NAME_PAGE, Action.CLICK, {
      item_name: 'submit',
      item_type: ItemType.BUTTON,
    });

    if (!displayName.trim()) {
      toast({
        title: 'Error',
        description: 'Display name cannot be empty.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.put('/api/user/updateDisplayName', {
        displayName: displayName.trim(),
      });

      if (response.status === 200) {
        dispatch(setAuthInfo({ displayName: displayName.trim() }));
      } else {
        throw new Error('Failed to update display name.');
      }
    } catch (error: any) {
      toast({
        title: 'Error',
        description: error.message || 'Failed to update display name.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box bg="black" minH="100vh" px={4} position="relative">
      <Flex direction="column" maxW="container.sm" mx="auto" pt={12} minH="100vh">
        <Text fontSize="4xl" fontWeight="bold" color="white" mb={2}>
          Enter name
        </Text>
        <Text fontSize="lg" color="gray.300" mb={8}>
          Add your name so your friends know who you are.
        </Text>

        <Input
          placeholder="Full Name"
          value={displayName}
          onChange={handleInputChange}
          size="lg"
          bg="white"
          color="black"
          borderRadius="full"
          height="60px"
          fontSize="md"
          _placeholder={{ color: 'gray.500' }}
          mb={4}
          _focus={{
            outline: 'none',
            boxShadow: 'none',
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !isLoading) {
              e.preventDefault();
              handleSubmit();
            }
          }}
        />

        <Button
          onClick={handleSubmit}
          isLoading={isLoading}
          bg="#FF0050"
          color="white"
          size="lg"
          height="60px"
          borderRadius="full"
          _hover={{ bg: '#df0045' }}
          fontSize="md"
          fontWeight="semibold"
          _focus={{
            outline: 'none',
            boxShadow: 'none',
          }}
        >
          Next
        </Button>

        <Flex
          position="absolute"
          bottom={4}
          left={0}
          right={0}
          justify="center"
          w="full"
          gap={2}
          color="#545454"
          fontSize="10px"
          fontWeight="bold"
        >
          <Link
            href="https://docs.google.com/document/d/1p3-fJ25CafAI13qyOYEZMu4rCH95JVaXRfsNejTyDD0/edit?usp=sharing"
            _hover={{ textDecoration: 'underline' }}
          >
            Terms of Service
          </Link>
          <Text>•</Text>
          <Link
            href="https://docs.google.com/document/d/1zImTFVA7nfo3-ZcbCk6Hr1Y67W5P9GHEhi8PPaUAxdc/edit?usp=sharing"
            _hover={{ textDecoration: 'underline' }}
          >
            Privacy Policy
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
}
