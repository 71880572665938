import { jwtDecode } from 'jwt-decode';

export const isSessionAboutToExpire = (sessionToken: string) => {
  const decoded = jwtDecode(sessionToken);
  const currentTime = Date.now() / 1000;
  // check if the session is expiring in the next 60 seconds
  if (decoded.exp && decoded.exp - 60 < currentTime) {
    return true;
  }
  return false;
};
