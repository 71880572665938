import { useEffect, useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { Box, Flex, Image, Link, Text, VStack } from '@chakra-ui/react';
import { Action, Page } from '@/../../server/types/loggingTypes';
import landingPagePhoneSvg from '@/assets/landingPage.gif';
import logo from '@/assets/logo.png';
import LoginWithEmail from '@/components/LoginWithEmail';
import PhoneVerification, { PhoneVerificationContext } from '@/components/PhoneVerification';
import { useAppSelector } from '@/store/hooks';
import { sizing } from '@/styles/sizing';
import { getStatsigClient } from '@/utils/statsig';

type SignupSearch = {
  enter_from?: string;
  email?: string;
  onboardingRewardUniqueId?: string;
};

export const Route = createFileRoute('/signup')({
  validateSearch: (search): SignupSearch => {
    const otpEmailFields =
      search.enter_from === 'otp_email' && search.email
        ? {
            enter_from: search.enter_from,
            email: search.email as string,
          }
        : {};
    return {
      ...otpEmailFields,
      onboardingRewardUniqueId: search.onboardingRewardUniqueId as string | undefined,
    };
  },
  component: LandingPage,
});

export enum LoginMethod {
  EMAIL = 'email',
  PHONE = 'phone',
}

export default function LandingPage() {
  const authState = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const [loginMethod, setLoginMethod] = useState<LoginMethod>(LoginMethod.PHONE);

  const { enter_from, email, onboardingRewardUniqueId } = useSearch({ from: '/signup' });

  useEffect(() => {
    if (authState.isAuthenticated) {
      navigate({ to: '/' });
    }

    if (enter_from === 'otp_email') {
      setLoginMethod(LoginMethod.EMAIL);
    }
  }, [authState.isAuthenticated, navigate, enter_from]);

  useEffect(() => {
    getStatsigClient().logEvent(Page.LANDING_PAGE, Action.VIEW);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Flex direction="column" minHeight="100vh" backgroundColor="#000000">
      {/* Header */}
      <Flex
        direction="row"
        align="center"
        justify={{ base: 'center', md: 'flex-start' }}
        py={6}
        px={{ base: 4, md: 12 }}
      >
        <Image src={logo} alt="Dots Logo" height={{ base: '32px', md: '40px' }} objectFit="contain" />
      </Flex>

      {/* Main Content */}
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align="center"
        justify="center"
        flex="1"
        py={0}
        px={{ base: 6, md: 6 }}
        mb={0}
        mt={0}
        backgroundColor="#000000"
        gap={'clamp(0px, 1vw, 0px)'}
        maxW="auto"
        mx="auto"
      >
        <Box
          width={{ base: '100%', md: '100%' }}
          mb={{ base: 8, md: 0 }}
          order={{ base: 2, md: 1 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          maxW={{ md: `${sizing.maxAppWidthPx}px` }}
          mr={{ md: 8 }}
        >
          <Image
            src={landingPagePhoneSvg}
            alt="Landing Page Visual"
            width={{ base: '500px', md: '600px' }}
            height={{ base: '500px', md: '600px' }}
            objectFit="contain"
            flexShrink={0}
            loading="eager"
            style={{ imageRendering: 'auto' }}
          />
        </Box>
        <Box
          width={{ base: '100%', md: '100%' }}
          mb={{ base: 8, md: 12 }}
          order={{ base: 1, md: 2 }}
          // maxW={{ md: "1000px" }}
        >
          <VStack align="center" spacing={4} width="100%">
            <Text
              color="white"
              fontSize={{ base: '24px', md: '30px', lg: '30px' }}
              fontWeight="bold"
              textAlign={{ base: 'center', md: 'left' }}
              lineHeight="shorter"
              mt={{ base: 4, md: 0 }}
              mb={{ base: 4, md: 4 }}
              maxW="500px"
            >
              Find your friends on Dots and get their help - instantly!
            </Text>

            <VStack align="start" spacing={4} width="100%" maxW={{ base: '325px', md: '450px' }} mb={4}>
              <Flex align="center" width="100%">
                <Flex
                  w="30px"
                  h="30px"
                  borderRadius="full"
                  bg="#000000"
                  justify="center"
                  align="center"
                  mr={3}
                  flexShrink={0}
                  border="1px solid white"
                >
                  <Text color="white" fontSize="md" fontWeight="bold">
                    1
                  </Text>
                </Flex>
                <Text color="white" fontSize={{ base: '14px', md: '18px' }} textAlign="left">
                  Create a profile – it's free & easy
                </Text>
              </Flex>

              <Flex align="center" width="100%">
                <Flex
                  w="30px"
                  h="30px"
                  borderRadius="full"
                  bg="#000000"
                  justify="center"
                  align="center"
                  mr={3}
                  flexShrink={0}
                  border="1px solid white"
                >
                  <Text color="white" fontSize="md" fontWeight="bold">
                    2
                  </Text>
                </Flex>
                <Text color="white" fontSize={{ base: '14px', md: '18px' }} textAlign="left">
                  Ask your friends any question you have
                </Text>
              </Flex>

              <Flex align="center" width="100%">
                <Flex
                  w="30px"
                  h="30px"
                  borderRadius="full"
                  bg="#000000"
                  justify="center"
                  align="center"
                  mr={3}
                  flexShrink={0}
                  border="1px solid white"
                >
                  <Text color="white" fontSize="md" fontWeight="bold">
                    3
                  </Text>
                </Flex>
                <Text color="white" fontSize={{ base: '14px', md: '18px' }} textAlign="left">
                  Get instant answers!
                </Text>
              </Flex>
            </VStack>

            {loginMethod === LoginMethod.EMAIL ? (
              <LoginWithEmail
                onboardingRewardUniqueId={onboardingRewardUniqueId}
                setLoginMethod={setLoginMethod}
                enterFrom={enter_from}
                otpEmailAddress={email}
              />
            ) : (
              <PhoneVerification
                onboardingRewardUniqueId={onboardingRewardUniqueId}
                setLoginMethod={setLoginMethod}
                context={PhoneVerificationContext.LandingPage}
              />
            )}
          </VStack>
        </Box>
      </Flex>

      {/* Back to Top Link (only for mobile) */}
      <Flex justify="center" w="full" pt={5} pb={12} display={{ base: 'flex', md: 'none' }}>
        <Link color="white" onClick={scrollToTop} textDecoration="underline">
          Back to top ↑
        </Link>
      </Flex>

      {/* Footer */}
      <Flex justify="center" w="full" pb={4} gap={2} color="#545454" fontSize="10px" fontWeight="bold">
        <Link
          href="https://docs.google.com/document/d/1p3-fJ25CafAI13qyOYEZMu4rCH95JVaXRfsNejTyDD0/edit?usp=sharing"
          _hover={{ textDecoration: 'underline' }}
        >
          Terms of Service
        </Link>
        <Text>•</Text>
        <Link
          href="https://docs.google.com/document/d/1zImTFVA7nfo3-ZcbCk6Hr1Y67W5P9GHEhi8PPaUAxdc/edit?usp=sharing"
          _hover={{ textDecoration: 'underline' }}
        >
          Privacy Policy
        </Link>
      </Flex>
    </Flex>
  );
}
