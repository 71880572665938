import { useState } from 'react';
import { Link, createFileRoute } from '@tanstack/react-router';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import ProfilePicUploader from '@/components/ProfilePicUploader';
import useRouterNavigation from '@/hooks/useRouterNavigation';
import { sizing } from '@/styles/sizing';

export const Route = createFileRoute('/_privateRoutes/settings/editProfilePicture')({
  component: RouteComponent,
});

function RouteComponent() {
  const { back } = useRouterNavigation();
  const [isUploading, setIsUploading] = useState(false);

  return (
    <Box bg="#1C1C1C" minH="100vh">
      <Box maxWidth={sizing.maxAppContentWidthPx} width="100%" mx="auto" p={4} textAlign="left">
        <Button
          leftIcon={<ChevronLeftIcon boxSize={5} left={0} right={0} p={0} />}
          alignSelf="flex-start"
          marginBottom={8}
          onClick={() => back()}
          backgroundColor="#1C1C1C"
          color="white"
          borderRadius="md"
          _hover={{ bg: '#2C2C2C' }}
          size="md"
        >
          Back
        </Button>
      </Box>

      <Flex direction="column" maxW="container.sm" mx="auto" pt={12} align="center">
        <Text fontSize="4xl" fontWeight="bold" color="white" mb={2} marginTop={20}>
          Change your profile photo
        </Text>

        <Text fontSize="lg" color="gray.300" mb={12}>
          Tap the profile photo to upload a new one
        </Text>

        <ProfilePicUploader
          size="165px"
          onUploadStart={() => {
            setIsUploading(true);
          }}
          onUploadComplete={() => setIsUploading(false)}
          onButtonClick={() => {}}
        />
      </Flex>

      <Flex position="absolute" bottom={0} left={0} right={0} p={4} justify="center" w="full">
        <Button
          as={Link}
          to="/settings"
          bg="#FF0050"
          color="white"
          size="lg"
          maxWidth={sizing.maxAppContentWidthPx}
          width="100%"
          height="60px"
          borderRadius="full"
          _hover={{ bg: 'gray.100' }}
          fontSize="md"
          fontWeight="semibold"
          isLoading={isUploading}
        >
          Continue
        </Button>
      </Flex>
    </Box>
  );
}
