import { Outlet, createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_privateRoutes')({
  component: Outlet,
  beforeLoad: async ({ context }) => {
    if (!context.auth.isAuthenticated) {
      return redirect({ to: '/signup' });
    }
  },
});
