import { Outlet, createFileRoute } from '@tanstack/react-router';
import { useAuthBasedNavigation } from '@/hooks/useAuthBasedNavigation';

export const Route = createFileRoute('/_privateRoutes/onboarding')({
  component: Onboarding,
});

function Onboarding() {
  useAuthBasedNavigation();
  return <Outlet />;
}
