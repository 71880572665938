import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { Box, Button, Center, Flex, Spinner } from '@chakra-ui/react';
import axios from 'axios';
import { FeedItem } from '@/../../server/types/apiTypes';
import { Action, Page } from '@/../../server/types/loggingTypes';
import InboxItem from '@/components/InboxItem';
import OnboardingProgressIndicator from '@/components/OnboardingProgressIndicator';
import { useAppSelector } from '@/store/hooks';
import { sizing } from '@/styles/sizing';
import { getStatsigClient } from '@/utils/statsig';

export const Route = createFileRoute('/_privateRoutes/_mainNavBar/inbox')({
  component: Inbox,
});

export default function Inbox() {
  const authState = useAppSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const userId = authState.userId; // Get userId from authentication state
  const [messageCards, setMessageCards] = useState<FeedItem[]>([]);
  const navigate = useNavigate({ from: '/inbox' });
  const [hasMore, setHasMore] = useState(true);
  const { ref, inView } = useInView();

  useEffect(() => {
    getStatsigClient().logEvent(Page.INBOX_TAB, Action.VIEW);
  }, []);

  // Add this before the useEffect hooks
  const fetchMoreFeedItems = async () => {
    try {
      setIsLoading(true);
      let cursor = undefined;
      if (messageCards.length !== 0) {
        const lastItem = messageCards[messageCards.length - 1];
        cursor = lastItem.createdAt;
      }
      const response = await axios.get('/api/loadMoreFeedItems', {
        params: {
          cursor: cursor,
        },
      });

      if (response.status !== 200) {
        throw new Error(`Network response was not ok, status: ${response.status}`);
      }

      if (response.data.length === 0) {
        setHasMore(false);
        return;
      }

      return response.data;
    } catch (error) {
      console.error('Error fetching more items:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch message cards from the database
  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      Promise.all([fetchMoreFeedItems()]).then(([newItems]) => {
        setMessageCards(newItems);
        setIsLoading(false);
      });
    }
  }, [userId]);

  const handleAddNoteClick = async () => {
    getStatsigClient().logEvent('clicked_add_note');
    navigate({ to: '/notes/new' });
  };

  useEffect(() => {
    const loadMore = async () => {
      if (inView && hasMore && !isLoading && messageCards.length > 0) {
        const newItems = await fetchMoreFeedItems();
        if (newItems) {
          setMessageCards((prev) => {
            const updatedCards = [...prev, ...newItems];
            return updatedCards;
          });
        }
      }
    };

    loadMore();
  }, [inView]);

  return (
    <Box width="100%" overflowX="hidden" mt={10} px={6}>
      <Flex
        direction="column"
        alignItems="center"
        gap={4}
        mb="100px"
        width="100%"
        maxW="100%"
        mx="auto"
        overflowY="auto"
        overflowX="hidden"
        flex="1"
      >
        {!authState.isOnBoardingTutorialComplete && (
          <OnboardingProgressIndicator currentStep={authState.numNotesOrQuestionsAnswered} />
        )}
        {messageCards.map((card) =>
          card.multiAskId && card.askerUserId === authState.userId ? (
            <InboxItem
              key={card.id}
              askerName={'You'}
              askerProfilePic={card.askerProfilePic}
              title={card.content}
              isViewed={true}
              isReplied={false}
              isNote={false}
              id={card.id}
              isNuxQuestion={false}
              askerId={card.askerUserId}
              receiverName={'your friends'}
              multiAskId={card.multiAskId}
            />
          ) : (
            <InboxItem
              key={card.id}
              askerName={card.askerUserId === authState.userId ? 'You' : card.askerName}
              askerProfilePic={card.askerProfilePic}
              title={card.content}
              isViewed={card.seenStatus}
              isReplied={card.feedbackSubmitted ?? false}
              isNote={card.isNote}
              id={card.id}
              isNuxQuestion={card.isNuxQuestion ?? false}
              askerId={card.askerUserId}
              receiverName={
                card.receiverUserId === card.askerUserId
                  ? 'Yourself'
                  : card.receiverUserId === authState.userId
                    ? 'You'
                    : card.receiverDisplayName
              }
            />
          ),
        )}

        <Box ref={ref} p={4} height="60px">
          {hasMore && isLoading && (
            <Center>
              <Spinner thickness="4px" speed="0.75s" emptyColor="#1c1c1c" color="#FF0050" size="md" />
            </Center>
          )}
        </Box>
      </Flex>
      <Box
        position="fixed"
        bottom="0"
        left="0"
        right="0"
        bg="#1c1c1c"
        p={4}
        textAlign="center"
        width="100%"
        maxWidth="100vw"
      >
        <Button
          width="100%"
          maxW={sizing.maxAppContentWidthPx}
          mx="auto"
          onClick={handleAddNoteClick}
          backgroundColor="#FF0050"
          color="white"
          height={{ base: '56px', md: '58px', lg: '60px' }}
          borderRadius="30px"
          _hover={{ bg: '#ef004b' }}
          fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
        >
          Add a Note
        </Button>
      </Box>
    </Box>
  );
}
