import { ReactNode, createContext, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { isPublicPath } from '@/../../shared/utils/auth';
import { isSessionAboutToExpire } from '@/utils/auth';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { AnonymousQuestionsState } from '../store/slices/anonymousQuestionsSlice';
import { AuthState, setAuthInfo } from '../store/slices/authSlice';
import { getStatsigClient } from '../utils/statsig';

// import {RootState} from "../store/store";

const REFRESH_TOKEN_URL = '/api/auth/refresh-token';

const AuthContext = createContext<{
  authState: {
    auth: AuthState;
    anonymousQuestions: AnonymousQuestionsState;
  };
  setAuthInfo: (userInfo: Partial<AuthState>) => void;
  checkUserOnboardingStatus: () => Promise<boolean>;
} | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const anonymousQuestions = useAppSelector(
    (state: { anonymousQuestions: AnonymousQuestionsState }) => state.anonymousQuestions,
  );
  const isHandlingAuthRef = useRef(false);

  const updateAuthInfo = (userInfo: Partial<AuthState>) => {
    if (userInfo.userId && userInfo.createdAt) {
      console.log(`Logging user ${userInfo.userId} to statsig`);
      getStatsigClient()
        .updateUserAsync({
          userID: userInfo.userId,
          custom: {
            createdAt: userInfo.createdAt.toString(),
            displayName: userInfo.displayName,
          },
        })
        .then(() => {
          console.log(`Logged user ${userInfo.userId} to statsig`);
        })
        .catch((err) => {
          console.error(`Error logging user ${userInfo.userId} to statsig`, err);
        });
    }
    dispatch(setAuthInfo(userInfo));
  };

  const fetchUserDetails = async () => {
    try {
      const response = await axios.post('/api/getUserDetails');
      console.log('User details retrieved successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error during user authentication and detail retrieval:', error);
      throw error;
    }
  };

  const checkUserOnboardingStatus = async (): Promise<boolean> => {
    try {
      const userDetails = await fetchUserDetails();
      if (!userDetails) {
        updateAuthInfo({
          isAuthenticated: false,
          isLoading: false,
        });
        return false;
      }
      updateAuthInfo({
        userId: userDetails.userId,
        isAuthenticated: true,
        displayNameCompleted: !!userDetails.displayName,
        displayName: userDetails.displayName,
        isOnboardingComplete: userDetails.isOnboardingComplete,
        isOnBoardingTutorialComplete: userDetails.isOnBoardingTutorialComplete,
        uniqId: userDetails.uniqId,
        isLoading: false,
        dateOfBirth: userDetails.dateOfBirth,
        profilePic: userDetails.profilePic,
        createdAt: userDetails.createdAt,
        onboardingRewardUserId: userDetails.onboardingRewardUserId,
        numNotesOrQuestionsAnswered: userDetails.numNotesOrQuestionsAnswered || 0,
        phoneNumber: userDetails.phoneNumber,
      });
      return true;
    } catch (error) {
      console.error('Error fetching user details:', error);
      return false;
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.post(REFRESH_TOKEN_URL, {}, { withCredentials: true });
      return response.data.sessionToken;
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }
  };

  const setAuthToken = (token: string) => {
    axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
    localStorage.setItem('sessionToken', token);
  };

  const refreshTokenPromiseRef = useRef<Promise<string> | null>(null);

  // Set default base URL for all axios requests
  useEffect(() => {
    axios.defaults.withCredentials = true;

    // Interceptor for requests
    const requestInterceptor = axios.interceptors.request.use(
      async (config) => {
        console.log('Request Interceptor, config:', config);

        if (config.url && !isPublicPath(config.url) && refreshTokenPromiseRef.current) {
          console.log('Request deferred because token refreshing is in progress');
          await refreshTokenPromiseRef.current;
        }

        let token = localStorage.getItem('sessionToken');
        console.log('Request Interceptor, token:', token);

        // Only refresh token if the request is hitting a private route and the token is about to expire
        if (config.url && !isPublicPath(config.url) && token && isSessionAboutToExpire(token)) {
          refreshTokenPromiseRef.current = refreshToken();
          token = await refreshTokenPromiseRef.current;
          setAuthToken(token);
          refreshTokenPromiseRef.current = null;
          console.log('Refreshed token:', token);
        }
        if (token && config.headers) {
          config.headers['authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    // Interceptor for responses
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        console.log('Response Interceptor, error:', error);
        const token = localStorage.getItem('sessionToken');
        const tokenExpired =
          error?.response?.status === 401 && token && isSessionAboutToExpire(token) && !originalRequest._retry;
        const jwtEmpty = error?.response?.status === 401 && !token;
        const hasRefreshToken = error?.response?.data?.hasRefreshToken;
        const isUrlWhitelisted = isPublicPath(originalRequest.url);
        const tryingToAccessNonExistentProfile = error?.response?.status === 404 && isUrlWhitelisted;
        if (hasRefreshToken && !isUrlWhitelisted && (tokenExpired || jwtEmpty)) {
          originalRequest._retry = true;
          try {
            const newToken = await refreshToken();
            setAuthToken(newToken);
            originalRequest.headers['authorization'] = `Bearer ${newToken}`;
            updateAuthInfo({ isAuthenticated: true });
            return axios(originalRequest);
          } catch (refreshError) {
            // Handle refresh token failure (e.g., logout user)
            updateAuthInfo({
              userId: null,
              isAuthenticated: false,
              isLoading: false,
            });
            localStorage.removeItem('sessionToken');
            Cookies.remove('refreshToken');
            console.log('redirecting to signup from response interceptor');
            window.history.pushState(null, '', '/signup');
            return Promise.reject(refreshError);
          }
        } else if (tryingToAccessNonExistentProfile) {
          console.log("Meaning user trying to access a profile that doesn't exist, do nothing");
        } else if (error?.response?.status === 401) {
          updateAuthInfo({
            isAuthenticated: false,
            isLoading: false,
            userId: null,
          });
          console.log(`request url: ${originalRequest.url} failed`);
        } else if (error?.response?.status === 404 && error?.response?.data?.error === 'User not found') {
          localStorage.removeItem('sessionToken');
          Cookies.remove('refreshToken');
          window.location.href = '/signup';
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  useEffect(() => {
    let mounted = true;

    const initAuthFlow = async () => {
      if (mounted && !authState.isAuthenticated && !isHandlingAuthRef.current) {
        isHandlingAuthRef.current = true;
        try {
          await handleAuthFlow();
        } finally {
          if (mounted) {
            isHandlingAuthRef.current = false;
          }
        }
      }
    };

    initAuthFlow();

    return () => {
      mounted = false;
    };
  }, []);

  const handleAuthFlow = async () => {
    console.log('handleAuthFlow called', {
      currentPath: window.location.href,
    });

    await checkUserOnboardingStatus();
  };

  if (authState.isLoading) {
    return <div>Loading...</div>; // Or any loading component
  }

  return (
    <AuthContext.Provider
      value={{
        authState: {
          auth: authState,
          anonymousQuestions: anonymousQuestions,
        },
        setAuthInfo: updateAuthInfo,
        checkUserOnboardingStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
