import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createFileRoute } from '@tanstack/react-router';
import { Box, Button, Flex, Image, Link, Select, Text, useToast } from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import { Action, ItemType, Page } from '@/../../server/types/loggingTypes';
import birthdayCake from '@/assets/birthday_cake.svg';
import { setAuthInfo } from '@/store/slices/authSlice';
import { getStatsigClient } from '@/utils/statsig';

export const Route = createFileRoute('/_privateRoutes/onboarding/addBirthday')({
  component: AddBirthday,
});

export default function AddBirthday() {
  useEffect(() => {
    getStatsigClient().logEvent(Page.BIRTHDAY_PAGE, Action.VIEW);
  }, []);

  const [month, setMonth] = useState((new Date().getMonth() + 1).toString().padStart(2, '0'));
  const [day, setDay] = useState(new Date().getDate().toString().padStart(2, '0'));
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();

  // Generate arrays for month, day, and year options
  const months = Array.from({ length: 12 }, (_, i) => {
    const monthNum = i + 1;
    return {
      value: monthNum.toString().padStart(2, '0'),
      label: new Date(2000, i).toLocaleString('default', { month: 'long' }),
    };
  });

  const days = Array.from({ length: 31 }, (_, i) => {
    const dayNum = i + 1;
    return {
      value: dayNum.toString().padStart(2, '0'),
      label: dayNum.toString(),
    };
  });

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => {
    const yearNum = currentYear - i;
    return {
      value: yearNum.toString(),
      label: yearNum.toString(),
    };
  });

  const handleSubmit = async () => {
    getStatsigClient().logEvent(Page.BIRTHDAY_PAGE, Action.CLICK, {
      item_name: 'submit',
      item_type: ItemType.BUTTON,
    });

    if (!month || !day || !year) {
      toast({
        title: 'Error',
        description: 'Please select your complete date of birth',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Check if user is at least 13 years old
    const birthDate = moment(`${year}-${month}-${day}`);
    const today = moment();
    const age = today.diff(birthDate, 'years');

    if (age < 13) {
      toast({
        title: 'Age Restriction',
        description: 'Sorry, you need to be at least 13 years old to use our product.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);

    try {
      const dateOfBirth = birthDate.format('YYYY-MM-DD');
      const response = await axios.put('/api/user/updateDateOfBirth', {
        dateOfBirth,
      });

      if (response.status === 200) {
        dispatch(setAuthInfo({ dateOfBirth }));
      } else {
        throw new Error('Failed to update date of birth');
      }
    } catch (error: any) {
      toast({
        title: 'Error',
        description: error.message || 'Failed to update date of birth',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box bg="black" minH="100vh" px={4}>
      <Flex direction="column" maxW="container.sm" mx="auto" pt={12} align="center">
        <Image src={birthdayCake} alt="Birthday cake" boxSize="102px" mb={6} />

        <Text fontSize="4xl" fontWeight="bold" color="white" mb={2}>
          Add your birthday
        </Text>

        <Text fontSize="lg" color="gray.300" mb={8}>
          This won&apos;t be part of your public profile.
        </Text>

        <Flex gap={4} width="100%" mb={6}>
          <Select
            placeholder="Month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            bg="white"
            color="black"
            borderRadius="full"
            height="60px"
            fontSize="md"
            flex={1}
            _focus={{
              outline: 'none',
              boxShadow: 'none',
            }}
          >
            {months.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>

          <Select
            placeholder="Day"
            value={day}
            onChange={(e) => setDay(e.target.value)}
            bg="white"
            color="black"
            borderRadius="full"
            height="60px"
            fontSize="md"
            flex={0.7}
            _focus={{
              outline: 'none',
              boxShadow: 'none',
            }}
          >
            {days.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>

          <Select
            placeholder="Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            bg="white"
            color="black"
            borderRadius="full"
            height="60px"
            fontSize="md"
            flex={0.8}
            _focus={{
              outline: 'none',
              boxShadow: 'none',
            }}
          >
            {years.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </Flex>

        <Text fontSize="sm" color="gray.400" mb={8}>
          You need to enter the date you were born
        </Text>

        <Button
          onClick={handleSubmit}
          isLoading={isLoading}
          bg="#FF0050"
          color="white"
          size="lg"
          width="100%"
          height="60px"
          borderRadius="full"
          _hover={{ bg: '#df0045' }}
          fontSize="md"
          fontWeight="semibold"
          _focus={{
            outline: 'none',
            boxShadow: 'none',
          }}
        >
          Next
        </Button>

        <Flex
          position="absolute"
          bottom={4}
          left={0}
          right={0}
          justify="center"
          w="full"
          gap={2}
          color="#545454"
          fontSize="10px"
          fontWeight="bold"
        >
          <Link
            href="https://docs.google.com/document/d/1p3-fJ25CafAI13qyOYEZMu4rCH95JVaXRfsNejTyDD0/edit?usp=sharing"
            _hover={{ textDecoration: 'underline' }}
          >
            Terms of Service
          </Link>
          <Text>•</Text>
          <Link
            href="https://docs.google.com/document/d/1zImTFVA7nfo3-ZcbCk6Hr1Y67W5P9GHEhi8PPaUAxdc/edit?usp=sharing"
            _hover={{ textDecoration: 'underline' }}
          >
            Privacy Policy
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
}
