import { useEffect } from 'react';
import { Link, createFileRoute, useNavigate, useRouter } from '@tanstack/react-router';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Image, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Action, Page } from '@/../../server/types/loggingTypes';
import defaultProfilePic from '@/assets/profile_pic_anon.png';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { clearAuth } from '@/store/slices/authSlice';
import { sizing } from '@/styles/sizing';
import { getStatsigClient } from '@/utils/statsig';

export const Route = createFileRoute('/_privateRoutes/_mainNavBar/settings')({
  component: Settings,
});

export default function Settings() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate({ from: '/settings' });
  const router = useRouter();
  const authState = useAppSelector((state) => state.auth);
  const handleLogout = async () => {
    try {
      localStorage.removeItem('sessionToken');
      Cookies.remove('refreshToken');
      delete axios.defaults.headers.common['Authorization'];
      await axios.post('/api/auth/logout');
      dispatch(clearAuth());
      router.invalidate();
      navigate({ to: '/signup' });
    } catch (error) {
      console.error('Sign out error:', error);
    }
  };

  useEffect(() => {
    getStatsigClient().logEvent(Page.SETTINGS_TAB, Action.VIEW);
  }, []);

  return (
    <Box width="100%" overflowX="hidden" mt={10} px={6}>
      <Flex direction="column" align="center" width="100%">
        <Image
          src={authState.profilePic ?? defaultProfilePic}
          alt="Profile Picture"
          boxSize="100px"
          borderRadius="full"
          objectFit="cover"
        />
        <Link
          to="/settings/editProfilePicture"
          style={{ color: '#FF0050', fontSize: '16px', fontWeight: 'bold', marginTop: '8px' }}
        >
          Edit
        </Link>
        <Box width="100%" maxW={sizing.maxAppContentWidthPx} mx="auto" pt={12}>
          <TableContainer>
            <Table variant="simple">
              <Tbody>
                <Tr onClick={() => navigate({ to: '/settings/editDisplayName' })} cursor="pointer">
                  <Td color="gray.500" borderColor="gray.500">
                    Name
                  </Td>
                  <Td color="white" borderColor="gray.500">
                    {authState.displayName}
                  </Td>
                  <Td color="gray.500" textAlign="right" borderColor="gray.500">
                    <ChevronRightIcon boxSize={8} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Flex>
      <Box
        position="fixed"
        bottom="0"
        left="0"
        right="0"
        bg="#1c1c1c"
        p={4}
        textAlign="center"
        width="100%"
        maxWidth="100vw"
      >
        <Button
          width="100%"
          maxW={sizing.maxAppContentWidthPx}
          mx="auto"
          onClick={handleLogout}
          backgroundColor="#FF0050"
          color="white"
          height={{ base: '56px', md: '58px', lg: '60px' }}
          borderRadius="30px"
          _hover={{ bg: '#ef004b' }}
          fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
        >
          Logout
        </Button>
      </Box>
    </Box>
  );
}
