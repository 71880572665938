import { useEffect } from 'react';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { Action, Page } from '@/../../server/types/loggingTypes';
import AskYourFriendsResults from '@/components/AskYourFriendsResults';
import { getStatsigClient } from '@/utils/statsig';

export const Route = createFileRoute('/_privateRoutes/ask-your-friends/$multiAskId')({
  component: AskYourFriendsResultsPage,
});

function AskYourFriendsResultsPage() {
  const { multiAskId } = useParams({ from: '/_privateRoutes/ask-your-friends/$multiAskId' });
  useEffect(() => {
    getStatsigClient().logEvent(Page.MULTI_ASK_DETAILS_PAGE, Action.VIEW);
  }, []);
  return <AskYourFriendsResults multiAskId={multiAskId} />;
}
