import type { AuthState } from '../store/slices/authSlice';

export const determineNextRoute = (authState: AuthState): string => {
  if (!authState.phoneNumber) {
    return '/onboarding/addPhoneNumber';
  }

  if (!authState.displayName) {
    return '/onboarding/addDisplayName';
  }

  if (!authState.dateOfBirth) {
    return '/onboarding/addBirthday';
  }

  if (!authState.isOnboardingComplete) {
    return '/onboarding/addProfilePicture';
  }

  return '/';
};
