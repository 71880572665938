import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Input, Text, useToast } from '@chakra-ui/react';
import axios from 'axios';
import useRouterNavigation from '@/hooks/useRouterNavigation';
import { useAppSelector } from '@/store/hooks';
import { setAuthInfo } from '@/store/slices/authSlice';
import { sizing } from '@/styles/sizing';

export const Route = createFileRoute('/_privateRoutes/settings/editDisplayName')({
  component: RouteComponent,
});

function RouteComponent() {
  const { back } = useRouterNavigation();
  const [displayName, setDisplayName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const authState = useAppSelector((state) => state.auth);
  const toast = useToast();
  const navigate = useNavigate({ from: '/settings/editDisplayName' });
  const dispatch = useDispatch();

  useEffect(() => {
    setDisplayName(authState.displayName!);
  }, [authState.displayName]);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      await axios.put('/api/user/updateDisplayName', {
        displayName,
      });

      dispatch(setAuthInfo({ displayName }));

      toast({
        title: 'Update successful',
        description: 'Your display name has been updated',
        status: 'success',
      });
      navigate({ to: '/settings' });
    } catch (error) {
      console.error(error);
      toast({
        title: 'Something went wrong',
        description: 'Please try again',
        status: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }, [displayName]);

  return (
    <Box bg="#1C1C1C" minH="100vh">
      <Box maxWidth={sizing.maxAppContentWidthPx} width="100%" mx="auto" p={4} textAlign="left">
        <Button
          leftIcon={<ChevronLeftIcon boxSize={5} left={0} right={0} p={0} />}
          alignSelf="flex-start"
          marginBottom={8}
          onClick={() => back()}
          backgroundColor="#1C1C1C"
          color="white"
          borderRadius="md"
          _hover={{ bg: '#2C2C2C' }}
          size="md"
        >
          Back
        </Button>
      </Box>

      <Flex direction="column" maxW="container.sm" mx="auto" pt={12} align="center">
        <Text fontSize="4xl" fontWeight="bold" color="white" mb={12} marginTop={20}>
          Change your display name
        </Text>

        <Input
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          size="lg"
          bg="white"
          color="black"
          borderRadius="full"
          height="60px"
          fontSize="md"
          _placeholder={{ color: 'gray.500' }}
          mb={4}
          _focus={{
            outline: 'none',
            boxShadow: 'none',
          }}
        />
      </Flex>

      <Flex position="absolute" bottom={0} left={0} right={0} p={4} justify="center" w="full">
        <Button
          bg="#FF0050"
          color="white"
          size="lg"
          maxWidth={sizing.maxAppContentWidthPx}
          width="100%"
          height="60px"
          borderRadius="full"
          _hover={{ bg: 'gray.100' }}
          fontSize="md"
          fontWeight="semibold"
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Flex>
    </Box>
  );
}
