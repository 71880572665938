import { useCallback } from 'react';
import { Navigate, createFileRoute, useNavigate } from '@tanstack/react-router';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import ConnectionItem, { ConnectionItemType } from '@/components/ConnectionItem';
import InfiniteScrollList from '@/components/lib/InfiniteScrollList';
import useConnectionActions from '@/hooks/useConnectionActions';
import { useGate } from '@/hooks/useGate';
import { usePendingConnectionsRequestsInfiniteQuery } from '@/hooks/usePendingConnectionsRequestsInfiniteQuery';
import useRouterNavigation from '@/hooks/useRouterNavigation';
import { sizing } from '@/styles/sizing';

export const Route = createFileRoute('/_privateRoutes/connections/requests')({
  component: Requests,
});

function Requests() {
  const checkGate = useGate('v1_connection_system');
  const navigate = useNavigate({ from: '/connections/requests' });
  const { back } = useRouterNavigation();

  if (!checkGate()) {
    return <Navigate to="/" />;
  }

  const handleProfileClick = useCallback(
    (uniqId: string) => () => {
      navigate({ to: '/profile/$uniqId', params: { uniqId } });
    },
    [navigate],
  );

  const query = usePendingConnectionsRequestsInfiniteQuery();
  const { generateRequestActions } = useConnectionActions();

  return (
    // tailwind somehow doesn't work with max-width: 600px, so we use inline style
    <div className="mx-auto" style={{ maxWidth: `${sizing.maxAppWidthPx}px` }}>
      <div className="flex items-center justify-center mx-6 relative">
        <div className="text-white absolute left-0" onClick={() => back()}>
          <ChevronLeftIcon boxSize={5} left={0} right={0} p={0} />
        </div>
        <div className="text-white text-lg font-bold px-3 py-4">Requests</div>
      </div>
      <InfiniteScrollList
        infiniteQuery={query}
        renderItem={(user) => (
          <ConnectionItem
            name={user.displayName}
            type={ConnectionItemType.CONNECTION_REQUEST}
            profilePicUrl={user.profilePic}
            onClick={handleProfileClick(user.uniqId)}
            actions={generateRequestActions(user.userId)}
          />
        )}
        heightClass="h-[calc(100vh-72px)]"
        estimateSizeFn={(_) => 40}
        paddingEnd={160}
        gap={16}
      />
    </div>
  );
}
