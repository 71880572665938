import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFileRoute } from '@tanstack/react-router';
import { Box, Button, Flex, HStack, Link, Text } from '@chakra-ui/react';
import axios from 'axios';
import { Action, ItemType, Page } from '@/../../server/types/loggingTypes';
import ProfilePicUploader from '@/components/ProfilePicUploader';
import { setAuthInfo } from '@/store/slices/authSlice';
import { RootState } from '@/store/store';
import { getStatsigClient } from '@/utils/statsig';
import { handleFileChange } from '@/utils/uploadProfilePic';

export const Route = createFileRoute('/_privateRoutes/onboarding/addProfilePicture')({
  component: ProfilePicUploadPage,
});

export default function ProfilePicUploadPage() {
  useEffect(() => {
    getStatsigClient().logEvent(Page.PROFILE_PIC_UPLOAD_PAGE, Action.VIEW);
  }, []);

  const dispatch = useDispatch();
  const authState = useSelector((state: RootState) => state.auth);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const updateStatus = async () => {
    const response = await axios.put('/api/user/updateOnboardingStatus');
    if (response.status !== 200) {
      throw new Error('Failed to update onboarding status.');
    }
    dispatch(setAuthInfo({ isOnboardingComplete: true }));
  };

  const handleSkip = () => {
    getStatsigClient().logEvent(Page.PROFILE_PIC_UPLOAD_PAGE, Action.CLICK, {
      item_name: 'skip',
      item_type: ItemType.BUTTON,
    });
    updateStatus();
  };

  const handleContinue = () => {
    getStatsigClient().logEvent(Page.PROFILE_PIC_UPLOAD_PAGE, Action.CLICK, {
      item_name: 'continue',
      item_type: ItemType.BUTTON,
    });
    updateStatus();
  };

  const handleUploadStart = useCallback(() => {
    getStatsigClient().logEvent(Page.PROFILE_PIC_UPLOAD_PAGE, Action.CLICK, {
      item_name: 'upload',
      item_type: ItemType.BUTTON,
    });
    setIsUploading(true);
  }, []);

  const hasProfilePic = authState.profilePic;

  return (
    <Box bg="black" minH="100vh" px={4} position="relative">
      <Box position="absolute" top={4} right={4}>
        <Button variant="ghost" color="white" _hover={{ color: 'white' }} onClick={handleSkip}>
          Skip
        </Button>
      </Box>

      <Flex direction="column" maxW="container.sm" mx="auto" pt={12} align="center">
        <Text fontSize="4xl" fontWeight="bold" color="white" mb={2} marginTop={20}>
          Add a profile photo
        </Text>

        <Text fontSize="lg" color="gray.300" mb={8}>
          Add a profile photo so your friends know it's you
        </Text>

        <ProfilePicUploader
          size="165px"
          onUploadStart={handleUploadStart}
          onUploadComplete={() => setIsUploading(false)}
          onButtonClick={() =>
            getStatsigClient().logEvent('click_upload_profile_pic_button_on_profile_pic_upload_page')
          }
        />

        <HStack spacing={4} width="100%" mt={6}>
          <Button
            onClick={() => {
              getStatsigClient().logEvent('click_upload_profile_pic_button_on_profile_pic_upload_page');
              fileInputRef.current?.click();
            }}
            isLoading={isUploading}
            bg="#FF0050"
            color="white"
            size="lg"
            width="100%"
            height="60px"
            borderRadius="full"
            _hover={{ bg: '#df0045' }}
            fontSize="md"
            fontWeight="semibold"
          >
            {hasProfilePic ? 'Change Photo' : 'Choose Photo'}
          </Button>

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={async (e) => {
              if (!e.target?.files || !e.target.files[0]) return;
              setIsUploading(true);
              try {
                const url = await handleFileChange(e);
                if (url) {
                  dispatch(setAuthInfo({ profilePic: url }));
                }
              } catch (error) {
                console.error('Error uploading profile picture:', error);
              } finally {
                setIsUploading(false);
              }
            }}
          />

          {hasProfilePic && (
            <Button
              onClick={handleContinue}
              bg="white"
              color="black"
              size="lg"
              width="100%"
              height="60px"
              borderRadius="full"
              _hover={{ bg: 'gray.100' }}
              fontSize="md"
              fontWeight="semibold"
            >
              Continue
            </Button>
          )}
        </HStack>

        <Flex
          position="absolute"
          bottom={4}
          left={0}
          right={0}
          justify="center"
          w="full"
          gap={2}
          color="#545454"
          fontSize="10px"
          fontWeight="bold"
        >
          <Link
            href="https://docs.google.com/document/d/1p3-fJ25CafAI13qyOYEZMu4rCH95JVaXRfsNejTyDD0/edit?usp=sharing"
            _hover={{ textDecoration: 'underline' }}
          >
            Terms of Service
          </Link>
          <Text>•</Text>
          <Link
            href="https://docs.google.com/document/d/1zImTFVA7nfo3-ZcbCk6Hr1Y67W5P9GHEhi8PPaUAxdc/edit?usp=sharing"
            _hover={{ textDecoration: 'underline' }}
          >
            Privacy Policy
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
}
