import { createFileRoute, useSearch } from '@tanstack/react-router';
import AskYourFriendsResults from '@/components/AskYourFriendsResults';

type AskYourFriendsSearch = {
  question?: string;
};

export const Route = createFileRoute('/_privateRoutes/ask-your-friends/')({
  component: RouteComponent,
  validateSearch: (search): AskYourFriendsSearch => {
    return {
      question: search.question as string | undefined,
    };
  },
});

function RouteComponent() {
  // TODO: change to state instead of search
  const { question } = useSearch({ from: '/_privateRoutes/ask-your-friends/' });
  return <AskYourFriendsResults question={question} />;
}
