import { useEffect, useMemo, useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { Box } from '@chakra-ui/react';
import { User } from '@/types/user';
import { Action, Page } from '@/../../server/types/loggingTypes';
import Ask from '@/components/Ask';
import { useAppSelector } from '@/store/hooks';
import { getStatsigClient } from '@/utils/statsig';

export const Route = createFileRoute('/_privateRoutes/_mainNavBar/self')({
  component: Self,
});

export default function Self() {
  const [prefilledQuestion, setPrefilledQuestion] = useState('');
  const authState = useAppSelector((state) => state.auth);
  const questionReceiver = useMemo<User | undefined>(() => {
    if (!authState.userId) {
      return undefined;
    }
    return {
      userId: authState.userId,
      displayName: authState.displayName ?? '',
      uniqId: authState.uniqId ?? '',
      profilePic: authState.profilePic,
    };
  }, [authState.userId, authState.displayName, authState.profilePic, authState.uniqId]);

  useEffect(() => {
    getStatsigClient().logEvent(Page.SELF_TAB, Action.VIEW);
  }, []);

  useEffect(() => {
    if (authState.justCompletedOnboardingTutorial) {
      setPrefilledQuestion(
        'i want you to give me: 1) Emoji: with my emoji 2) MBTI: my MBIT 3) Enneagram: my enneagram 4) Spirit Animal: my spirit animal and 5) Recommendation: An obscure recommendation (movie, song, book, TV) that i may enjoy with the year and content type. only provide me the concise answer with google link and a quick reason why in 10 words or less in one column',
      );
    }
  }, [authState.justCompletedOnboardingTutorial]);

  return (
    <Box mt={10} px={6}>
      <Ask questionReceiver={questionReceiver} prefillQuestion={prefilledQuestion} isInternal={true} />
    </Box>
  );
}
