import { useCallback, useEffect, useRef } from 'react';
import { Outlet, createFileRoute, redirect, useLocation, useNavigate } from '@tanstack/react-router';
import { Box, Flex, Image, VStack } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';
import { Action, ItemType, Page } from '@/../../server/types/loggingTypes';
import logo from '@/assets/logo.png';
import NavAskYourFriends from '@/assets/nav_ask_your_friends.png';
import NavAskYourFriendsSelected from '@/assets/nav_ask_your_friends_selected.png';
import NavFindNewContact from '@/assets/nav_find_new_contact.png';
import NavFindNewContactSelected from '@/assets/nav_find_new_contact_selected.png';
import NavInbox from '@/assets/nav_inbox.png';
import NavInboxSelected from '@/assets/nav_inbox_selected.png';
import NavInternalQuestions from '@/assets/nav_internal_questions.png';
import NavInternalQuestionsSelected from '@/assets/nav_internal_questions_selected.png';
import NavMenu from '@/assets/nav_menu.png';
import NavMenuSelected from '@/assets/nav_menu_selected.png';
import NavButton from '@/components/NavButton';
import OpenAppButton from '@/components/OpenAppButton';
import { useAppSelector } from '@/store/hooks';
import { topNavRefAtom } from '@/store/layoutAtom';
import { sizing } from '@/styles/sizing';
import { determineNextRoute } from '@/utils/navigation';
import { getStatsigClient } from '@/utils/statsig';

export const Route = createFileRoute('/_privateRoutes/_mainNavBar')({
  component: MainNavBar,
  beforeLoad: async ({ context, location }) => {
    if (!context.auth.isOnboardingComplete) {
      return redirect({ to: determineNextRoute(context.auth), replace: true });
    }
    if (location.pathname !== '/inbox' && !context.auth.isOnBoardingTutorialComplete) {
      return redirect({ to: '/inbox', replace: true });
    }
  },
});

export default function MainNavBar() {
  const isOnBoardingTutorialComplete = useAppSelector((state) => state.auth.isOnBoardingTutorialComplete);

  const navigate = useNavigate();
  const handleNavButtonClick = useCallback(
    (path: string) => () => {
      const itemName = path === '/' ? 'search' : path.replace('/', '');
      getStatsigClient().logEvent(Page.MAIN_NAV_BAR, Action.CLICK, {
        item_name: itemName,
        item_type: ItemType.BUTTON,
      });

      navigate({ to: path });
    },
    [navigate],
  );

  const pathname = useLocation({
    select: (location) => location.pathname,
  });

  const setTopNavRef = useSetAtom(topNavRefAtom);
  const topNavRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTopNavRef(topNavRef);
  }, [topNavRef.current, setTopNavRef]);

  return (
    <Flex
      style={{
        backgroundColor: '#1C1C1C',
        overflowX: 'hidden',
      }}
      justifyContent="center"
      width="100%"
    >
      <Box maxWidth={sizing.maxAppWidthPx} width="full">
        <VStack
          ref={topNavRef}
          position="fixed"
          top={0}
          zIndex={10}
          maxWidth={sizing.maxAppWidthPx}
          width="full"
          bg="#1c1c1c"
          gap={0}
        >
          <Flex direction="row" align="center" justify="center" py={4} width="100%" position="relative">
            <Image src={logo} alt="Dots Logo" height={{ base: '32px', md: '40px' }} objectFit="contain" />
            <OpenAppButton right="4" deeplinkPath="/" />
          </Flex>
          <Flex
            direction="row"
            align="center"
            justify="space-around"
            width="100%"
            maxWidth={sizing.maxAppWidthPx}
            pt={4}
            pb={2.5}
            borderBottom="1px solid #898989"
          >
            <NavButton
              selected={pathname === '/connections'}
              image={NavFindNewContact}
              selectedImage={NavFindNewContactSelected}
              alt="Find New Contact"
              disabled={!isOnBoardingTutorialComplete}
              onClick={handleNavButtonClick('/connections')}
            />
            <NavButton
              selected={pathname === '/self'}
              image={NavInternalQuestions}
              selectedImage={NavInternalQuestionsSelected}
              alt="Profile"
              disabled={!isOnBoardingTutorialComplete}
              onClick={handleNavButtonClick('/self')}
            />
            <NavButton
              selected={pathname === '/'}
              image={NavAskYourFriends}
              selectedImage={NavAskYourFriendsSelected}
              alt="Ask Your Friends"
              disabled={!isOnBoardingTutorialComplete}
              onClick={handleNavButtonClick('/')}
            />
            <NavButton
              selected={pathname === '/inbox'}
              image={NavInbox}
              selectedImage={NavInboxSelected}
              alt="Inbox"
              disabled={false}
              onClick={handleNavButtonClick('/inbox')}
            />
            <NavButton
              selected={pathname === '/settings'}
              image={NavMenu}
              selectedImage={NavMenuSelected}
              alt="Menu"
              disabled={!isOnBoardingTutorialComplete}
              onClick={handleNavButtonClick('/settings')}
            />
          </Flex>
        </VStack>
        <Box pt={pathname === '/connections' ? '215px' : '120px'}>
          <Outlet />
        </Box>
      </Box>
    </Flex>
  );
}
