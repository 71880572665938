// statsigUtils.ts
import { StatsigClient } from '@statsig/react-bindings';
import { runStatsigSessionReplay } from '@statsig/session-replay';
import { runStatsigAutoCapture } from '@statsig/web-analytics';

// Create a singleton instance
let statsigInstance: StatsigClient | null = null;
export const statsigSDKKey = 'client-qj7ir3Szut3OVbEN3O5FLDMaFdZikhFu8fZnqgU52Pg';

export const getStatsigClient = (): StatsigClient => {
  if (statsigInstance) {
    return statsigInstance;
  }

  try {
    console.log('Statsig client not initialized');
    const client = new StatsigClient(
      statsigSDKKey,
      {},
      {
        environment: {
          tier: process.env.NODE_ENV === 'production' ? 'production' : 'development',
        },
      },
    );
    client.initializeSync();

    // Initialize additional features
    runStatsigAutoCapture(client);
    runStatsigSessionReplay(client);

    statsigInstance = client;
    return client;
  } catch (error) {
    console.error('Failed to initialize Statsig:', error);
    throw error;
  }
};
