import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Navigate, createFileRoute, useNavigate, useSearch } from '@tanstack/react-router';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useInfiniteQuery } from '@tanstack/react-query';
import axios from 'axios';
import { SearchUsersResponse } from '@/../../server/types/apiTypes';
import ConnectionItem, { ConnectionItemType } from '@/components/ConnectionItem';
import InfiniteScrollList from '@/components/lib/InfiniteScrollList';
import SearchInput from '@/components/lib/SearchInput';
import { useGate } from '@/hooks/useGate';
import useRouterNavigation from '@/hooks/useRouterNavigation';
import { sizing } from '@/styles/sizing';

type ConnectionSearchParams = {
  keyword: string;
};

export const Route = createFileRoute('/_privateRoutes/connections/search')({
  validateSearch: (search): ConnectionSearchParams => {
    return {
      keyword: (search.keyword as string) ?? '',
    };
  },
  component: RouteComponent,
});

async function searchUsers(keyword: string, page: number) {
  if (keyword.length === 0) {
    return {
      hasMore: false,
      list: [],
    };
  }

  const TAKE = 20;
  const skip = page * TAKE;
  const resp = await axios.get<SearchUsersResponse>(
    `/api/social/searchUsers?keyword=${keyword}&take=${TAKE}&skip=${skip}`,
  );

  if (resp.data.success && resp.data.data) {
    const { total, list } = resp.data.data;
    return {
      hasMore: total > TAKE + skip,
      list,
    };
  }
  return {
    hasMore: false,
    list: [],
  };
}

function RouteComponent() {
  const { keyword } = useSearch({ from: '/_privateRoutes/connections/search' });
  const [newKeyword, setNewKeyword] = useState(keyword);
  const checkGate = useGate('v1_connection_system');

  const query = useInfiniteQuery({
    queryKey: ['searchUsers', keyword],
    queryFn: async ({ pageParam, queryKey: [, keyword] }) => searchUsers(keyword, pageParam),
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.hasMore) {
        return lastPageParam + 1;
      }
      return undefined;
    },
    initialPageParam: 0,
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate({ from: '/connections/search' });

  useEffect(() => {
    if (newKeyword && keyword !== newKeyword) {
      navigate({ to: '/connections/search', search: { keyword: newKeyword }, replace: true });
    }
  }, [newKeyword, keyword]);

  const { back } = useRouterNavigation();

  const handleProfileClick = useCallback(
    (uniqId: string) => () => {
      navigate({ to: '/profile/$uniqId', params: { uniqId } });
    },
    [navigate],
  );

  const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      inputRef.current?.blur();
    }
  }, []);

  if (!checkGate()) {
    return <Navigate to="/" />;
  }

  return (
    // tailwind somehow doesn't work with max-width: 600px, so we use inline style
    <div className="mx-auto" style={{ maxWidth: `${sizing.maxAppWidthPx}px` }}>
      <div className="flex items-center mx-6">
        <div className="text-white flex-initial" onClick={() => back()}>
          <ChevronLeftIcon boxSize={5} left={0} right={0} p={0} />
        </div>
        <div className="w-full px-3 py-4 mr-5">
          <SearchInput
            ref={inputRef}
            value={newKeyword}
            onChange={(value) => setNewKeyword(value)}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      <InfiniteScrollList
        infiniteQuery={query}
        renderItem={(user) => (
          <ConnectionItem
            name={user.displayName}
            type={ConnectionItemType.CONNECTED}
            profilePicUrl={user.profilePic}
            onClick={handleProfileClick(user.uniqId)}
          />
        )}
        heightClass="h-[calc(100vh-72px)]"
        estimateSizeFn={(_) => 40}
        paddingEnd={160}
        gap={16}
      />
    </div>
  );
}
