export const isPublicPath = (path: string) => {
  const publicPaths = [
    '/api/auth/refresh-token',
    '/api/auth/authenticate',
    '/api/auth/logout',
    '/api/auth/sendEmail',
    '/api/getAskeeUser',
    '/api/auth/sendSignInLink',
    '/api/auth/decryptEmail',
    '/api/auth/sendSmsVerificationCode',
    '/api/auth/verifySmsCode',
    '/api/auth/sendEmailVerificationCode',
    '/api/auth/verifyEmailCode',
  ];

  return publicPaths.includes(path);
};
