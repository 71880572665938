import { Middleware, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import anonymousQuestionsReducer, { AnonymousQuestionsState } from './slices/anonymousQuestionsSlice';
import authReducer from './slices/authSlice';

const anonymousQuestionsPersistConfig = {
  key: 'anonymousQuestions',
  storage,
  whitelist: ['count', 'askedQuestions'],
  debug: true,
};

const config = {
  channel: 'dots_app_sync',
  // Sync all anonymousQuestions actions
  predicate: (action: { type: string }) => {
    return action.type.startsWith('anonymousQuestions/');
  },
  // Only blacklist Redux Persist actions
  blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
};

type AnonymousQuestionsAction = Parameters<typeof anonymousQuestionsReducer>[1];

export const store = configureStore({
  reducer: {
    auth: authReducer,
    anonymousQuestions: persistReducer<AnonymousQuestionsState, AnonymousQuestionsAction>(
      anonymousQuestionsPersistConfig,
      anonymousQuestionsReducer,
    ),
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }).concat(createStateSyncMiddleware(config) as Middleware),
});

export const persistor = persistStore(store);

// Initialize the message listener
initMessageListener(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
