import { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useAtomValue } from 'jotai';
import { Action, Page } from '@/../../server/types/loggingTypes';
import ConnectedConnections from '@/components/Connections/ConnectedConnections';
import PendingConnectionRequests from '@/components/Connections/PendingConnectionRequests';
import SearchInput from '@/components/lib/SearchInput';
import { useGate } from '@/hooks/useGate';
import { topNavRefAtom } from '@/store/layoutAtom';
import { getStatsigClient } from '@/utils/statsig';

export const Route = createFileRoute('/_privateRoutes/_mainNavBar/connections')({
  component: RouteComponent,
});

function RouteComponent() {
  useEffect(() => {
    getStatsigClient().logEvent(Page.CONNECTIONS_TAB, Action.VIEW);
  }, []);

  const checkGate = useGate('v1_connection_system');
  const topNavRef = useAtomValue(topNavRefAtom);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate({ from: '/connections' });

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        navigate({ to: '/connections/search', search: { keyword: searchQuery } });
      }
    },
    [searchQuery],
  );

  return (
    <div>
      {checkGate() && (
        <>
          {topNavRef?.current &&
            ReactDOM.createPortal(
              <div className="w-full left-0 px-3 py-4">
                <SearchInput
                  value={searchQuery}
                  onChange={(value) => setSearchQuery(value)}
                  onKeyDown={handleKeyDown}
                />
              </div>,
              topNavRef.current,
            )}
          <div className="flex flex-col gap-9">
            <PendingConnectionRequests />
            <ConnectedConnections />
          </div>
        </>
      )}
    </div>
  );
}
